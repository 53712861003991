import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import ManPeoplePerson from 'assets/img/svg/man_people_person.svg';
import Logo from 'assets/img/svg/familia.svg';
import UsersIcon from 'assets/img/svg/users.svg';
import classes from './styles.module.scss';
import { GroupsEnum } from 'types/users';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';
import { $store, $storeList } from 'models/store';

export interface navLinkItem {
	id: number;
	title: string;
	path: string;
	img: string;
	scope: string[];
	sub: navLinkItem[];
}

const navLinksList = [
	{
		id: 1,
		title: 'Статистика присутствия в магазине',
		path: '/admin/stores',
		scope: [
			GroupsEnum.SESSION_STAT,
			GroupsEnum.ADMIN,
			GroupsEnum.RD1,
			GroupsEnum.RD2,
			GroupsEnum.RD3,
			GroupsEnum.RD4,
			GroupsEnum.RD5,
			GroupsEnum.RD6,
			GroupsEnum.RD7,
			GroupsEnum.RD8,
			GroupsEnum.RD9,
			GroupsEnum.RD10,
			GroupsEnum.RD11,
			GroupsEnum.RD12,
		],
		img: UsersIcon,
		sub: [],
	},
	{
		id: 2,
		title: 'Нарушения по присутствию в магазине',
		path: '/admin/broken',
		img: UsersIcon,
		scope: [
			GroupsEnum.SESSION_STAT,
			GroupsEnum.ADMIN,
			GroupsEnum.RD1,
			GroupsEnum.RD2,
			GroupsEnum.RD3,
			GroupsEnum.RD4,
			GroupsEnum.RD5,
			GroupsEnum.RD6,
			GroupsEnum.RD7,
			GroupsEnum.RD8,
			GroupsEnum.RD9,
			GroupsEnum.RD10,
			GroupsEnum.RD11,
			GroupsEnum.RD12,
		],
		sub: [],
	},
	{
		id: 3,
		title: 'Нарушения по фотофиксации ручных смен',
		path: '/admin/photo',
		scope: [
			GroupsEnum.SESSION_STAT,
			GroupsEnum.ADMIN,
			GroupsEnum.RD1,
			GroupsEnum.RD2,
			GroupsEnum.RD3,
			GroupsEnum.RD4,
			GroupsEnum.RD5,
			GroupsEnum.RD6,
			GroupsEnum.RD7,
			GroupsEnum.RD8,
			GroupsEnum.RD9,
			GroupsEnum.RD10,
			GroupsEnum.RD11,
			GroupsEnum.RD12,
		],
		img: UsersIcon,
		sub: [],
	},
];

function CustomLinkSub({ children, to }: LinkProps) {
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: true });
	return (
		<li>
			<Link to={to} className={`${classes['main-menu-sublink']}  ${match && classes.active}`}>
				{children}
			</Link>
		</li>
	);
}

function SubMenu({ item }: { item: navLinkItem }) {
	const [opensub, setOpensub] = React.useState(false);
	const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		setOpensub(!opensub);
	};
	return (
		<>
			<a
				href="#sub"
				className={`${classes['main-menu-link']}  ${classes['main-menu-link--drop']} ${opensub && classes.open}`}
				onClick={handleClick}
			>
				<span className={classes['main-menu-link__icon']}>
					<img alt="icon" src={ManPeoplePerson} style={{ filter: 'brightness(0) invert(1)' }} />
				</span>
				<span className={classes['main-menu-link__txt']}>{item.title}</span>
			</a>
			<ul className={classes['main-menu-sublist']}>
				{item.sub.map((item: navLinkItem) => (
					<CustomLinkSub to={item.path} key={item.id}>
						{item.title}
					</CustomLinkSub>
				))}
			</ul>
		</>
	);
}

function MainMenuItem({ item }: { item: navLinkItem }) {
	const resolved = useResolvedPath(item.path);
	const store = useUnit($store);
	const user = useUnit($user);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<li>
			{item.sub && item.sub.length > 0 ? (
				<SubMenu item={item} />
			) : (
				<>
					{user && Array.isArray(user.groups) && user?.groups.filter((x: string) => item.scope.includes(x)).length > 0 && (
						<Link to={item.path} className={`${classes['main-menu-link']} ${match && classes.active}`}>
							<span className={classes['main-menu-link__icon']}>
								<img alt="icon" src={item.img} style={{ filter: 'brightness(0) invert(1)' }} />
							</span>
							<span className={classes['main-menu-link__txt']}>{item.title}</span>
						</Link>
					)}
				</>
			)}
		</li>
	);
}

export default function Sidebar() {
	console.log('Sidebar rendered', navLinksList, $user);

	return (
		<div className={classes.sidebar}>
			<div className={classes.sidebar__header}>
				<a href="/" className={classes.logo}>
					<img src={Logo} alt="" />
					<img src={Logo} alt="" />
				</a>
			</div>
			<div className={classes.sidebar__main}>
				<nav className={classes['main-menu']}>
					<ul>
						{navLinksList.map(item => (
							<MainMenuItem item={item} key={item.id} />
						))}
					</ul>
				</nav>
			</div>
			<div className={classes.sidebar__footer}>
				<div className={classes.copyright}>©️ 2000-{new Date().getFullYear()} Familia</div>
			</div>
		</div>
	);
}
