import Border from 'components/Border';
import classes from '../AdminLayout/Header/styles.module.scss';
import ProfileMenu from 'components/AdminLayout/Topbar/ProfileMenu';
import Topbar from 'components/AdminLayout/Topbar';
import { $user } from 'models/user';
import { useUnit } from 'effector-react';
import Avatar from 'assets/img/svg/avatar.svg';
import styles from './styles.module.scss';
import cn from 'classnames';
import { Outlet } from 'react-router-dom';
import React from 'react';

export default function Layout({ children }: { children?: React.ReactNode }) {
	const user = useUnit($user);
	return (
		<Border>
			<div className={styles['wrap']}>
				<div className={styles['top-side']}>
					<h2 className={styles['top-side-left']}></h2>
					<div className={styles['top-side-right']}>
						<div className={classes.header__bar}>
							<div className={classes['header__bar-item']}>
								<div className={`${classes['profile-bar']} profile-bar`}>
									<div className={classes['profile-bar__wrap']}>
										<div className={classes['profile-bar__main']}>
											<div className={classes['profile-bar__name']}>{user?.firstname}</div>
											<div className={classes['profile-bar__sub']}>{user?.secondname}</div>
										</div>
										<div className={classes['profile-bar__avatar']}>
											<div className={cn(classes.avatar, classes['avatar--md'])}>
												<img width="50px" src={Avatar} alt="" />
											</div>
										</div>
									</div>
									<Topbar>
										<ProfileMenu />
									</Topbar>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles['body-side']}>{React.isValidElement(children) ? children : <Outlet />}</div>
			</div>
		</Border>
	);
}
