import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import 'react-html5-camera-photo/build/css/index.css';
import { imagebase64 } from 'utils/imagebase64';
import Button from 'components/UI/Button';
import Camera from 'components/Camera';
import { dataURItoBlob } from 'utils/helpers';
import styles from './styles.module.scss';
import moment from 'moment';

const datetime = moment(new Date()).format('YYYY-MM-DD HH:mm');

export default function PhotoUser() {
	const { post, get } = useFetch();
	const navigate = useNavigate();
	const { outsourceId, sessionId } = useParams();
	const [loading, setLoading] = React.useState(false);

	const [facingMode, setFacingMode] = React.useState(false);

	const handleClick = React.useCallback(() => {
		setFacingMode(!facingMode);
	}, [setFacingMode, facingMode]);

	const [image, setImage] = React.useState('');

	const handleTakePhoto = React.useCallback(
		(dataUri: string) => {
			setImage(dataUri);
		},
		[setImage],
	);

	const handleSave = React.useCallback(async () => {
		setLoading(true);
		const file = dataURItoBlob(image);
		const body = new FormData();
		body.append('file', file, 'image.jpg');

		const imageRes = await post('api/file/download', body);

		if (imageRes) {
			if (sessionId) {
				navigate(`/outsourcing/accept/${outsourceId}/open/${imageRes.id}/${sessionId}`);
			} else {
				navigate(`/outsourcing/accept/${outsourceId}/open/${imageRes.id}`);
			}
		}
		setLoading(false);
	}, [image, setLoading, outsourceId]);

	return (
		<div className="column-xs-12">
			{image ? (
				<div className={styles.wrap}>
					<div className="row">
						<div className="column-xs-12">
							<h2>Сделайте фото сотрудника с удостоверением личности</h2>
							<img alt="img" src={image} style={{ width: 'auto' }} />
						</div>
					</div>
					<div className="row">
						<div className="column-xs-4">
							<Button variant="violet" type="button" onClick={() => navigate('/qrcode')} style={{ margin: 'auto' }}>
								Назад
							</Button>
						</div>
						<div className="column-xs-4">
							<Button variant="orange" type="button" onClick={() => setImage('')} style={{ margin: 'auto' }}>
								Переснять
							</Button>
						</div>
						<div className="column-xs-4">
							<Button variant="green" disabled={loading} type="button" onClick={handleSave} style={{ margin: 'auto' }}>
								{loading ? 'Загрузка...' : 'Подтвердить'}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div>
					<Button type="button" onClick={handleClick} style={{ margin: '10px auto' }}>
						Смена камеры
					</Button>
					<Camera handleTakePhoto={handleTakePhoto} facingMode={facingMode} />
					<div style={{ margin: '40px' }}>
						{/* <Input type="datetime-local" onChange={handleChange} name="date" label="Введите дату открытия/закрытия смены" defaultValue={datetime} /> */}
						<p>Дата и время регистрации прихода/ухода</p>
						<p>{datetime}</p>
						<br />
						<p>
							Сделайте фото сотрудника аутсорс с удостоверением личности во входной зоне универмага (перед вывеской
							Фамилия)
						</p>
					</div>
				</div>
			)}
		</div>
	);
}
