import classes from '../Header/styles.module.scss';

export default function Topbar({ children }: { children: React.ReactNode }) {
	return (
		<div className={`profile-bar__drop ${classes['profile-bar__drop']} ${classes.drop} ${classes['drop--right-pos']}`}>
			<div className={classes.drop__wrap}>
				<nav className={classes['drop-nav']}>{children}</nav>
			</div>
		</div>
	);
}
