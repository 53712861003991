import React from 'react';
import styles from './styles.module.scss';
import UserForm from './UserForm';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import { $store } from 'models/store';
import { useUnit } from 'effector-react';
import { ISession } from 'types/session';

export default function AnotherUser() {
	const navigate = useNavigate();
	const { post, get, patch } = useFetch();
	const store = useUnit($store);

	const { sessionId } = useParams();

	const [session, setSession] = React.useState<ISession | undefined>();

	React.useEffect(() => {
		init();
	}, [sessionId]);

	async function init() {
		const result = await get(`/api/session/${sessionId}`);
		setSession(result);
	}

	const handleSubmit = React.useCallback(
		async (data: any) => {
			const outsUser = await post('api/users/outsource', {
				firstname: data.firstname,
				document: data.document,
			});
			const userId = outsUser.id;

			const outsourceBody = {
				storeId: store?.id,
				userId,
				counterpartyId: session?.request?.counterparty?.id,
				positionId: session?.request?.position?.id,
				position: session?.request?.position?.title,
			};

			const outsource = await post('/api/outsourcing', outsourceBody);
			await patch(`/api/session/${sessionId}`, {
				outsourcingId: outsource.id,
				userId,
			});

			navigate(`/outsourcing`);
		},
		[session],
	);

	return (
		<div className={styles['section-list']}>
			<h2>Заполните обязательные поля</h2>
			<UserForm onSubmit={handleSubmit} short />
		</div>
	);
}
