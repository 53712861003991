import { KeycloakProfile } from 'keycloak-js';

export enum RolesEnum {
	Admin = 'Admin',
	Store = 'Store',
}

export enum OutsourceRolesEnum {
	Scrubwoman = 'Уборщица',
	Porter = 'Грузчик',
	Cashier = 'Кассир-аутсорсер',
	Salesman = 'Продавец-аутсорсер',
}

export enum GroupsEnum {
	CLIENT = '/smena/smena. общее',
	ADMIN = '/smena/smena. администрирование',
	GEN_QRCODE = '/smena/smena. генерация qr-кода',
	BREACH_STAT = '/smena/smena. отчет по нарушениям присутствия в магазинах',
	MANUAL_REG = '/smena/smena. ручная регистрация сотрудника в журнале',
	SESSION_STAT = '/smena/smena. отчет статистики присутствия',
	OUTSOURCE = '/smena/smena. ручная регистрация аутсорсера в журнале',
	PHOTO_VIOLATION_STAT = '/smena/smena. отчет по нарушениям фотофиксации ручных смен',
	RD1 = '/rdaccess/rdaccess. rd1',
	RD2 = '/rdaccess/rdaccess. rd2',
	RD3 = '/rdaccess/rdaccess. rd3',
	RD4 = '/rdaccess/rdaccess. rd4',
	RD5 = '/rdaccess/rdaccess. rd5',
	RD6 = '/rdaccess/rdaccess. rd6',
	RD7 = '/rdaccess/rdaccess. rd7',
	RD8 = '/rdaccess/rdaccess. rd8',
	RD9 = '/rdaccess/rdaccess. rd9',
	RD10 = '/rdaccess/rdaccess. rd10',
	RD11 = '/rdaccess/rdaccess. rd11',
	RD12 = '/rdaccess/rdaccess. rd12',
}

export const RolesConst = {
	Admin: 'Admin',
};

export const getRole = (role: string): string => {
	switch (role) {
		case RolesEnum.Admin:
			return 'Admin';
		default:
			return 'Неизвестная роль';
	}
};

export enum PositionType {
	Office = 'office', // офис
	Market = 'market', // магазин
}

export interface IPosition {
	createdAt: Date;
	id: string;
	title: string;
	type: PositionType;
	unitId: string;
	updatedAt: Date;
	position_id: string;
}

export interface IUser {
	id: string;
	firstname: string;
	secondname: string;
	middlename: string;
	createdAt: Date;
	updatedAt: Date;
	position: IPosition;
	document?: string;
	document_series?: number;
	document_number?: number;
	outsourcePosition?: string;
	counterparty?: string;
}

export interface CustomKCProfile extends KeycloakProfile {
	attributes?: {
		num: string[];
		phoneNumber: string[];
		worklist: string[];
	};
}

export interface UserFilter {
	userId: string;
	secondname: string;
	firstname: string;
	middlename: string;
}
