import React from 'react';
import styles from './styles.module.scss';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import Sessions from 'components/Session';
import useFetch from 'use-http';
import { ISession } from 'types/session';
import Border from 'components/Border';
import WelcomeShopIcon from 'assets/img/pic/welcome_shop.png';
import moment from 'moment';
import AgreementUser from './AgreementUser';
import { GroupsEnum } from 'types/users';
import { useKeycloak } from '@react-keycloak/web';
import { $user, delToken } from 'models/user';
import { useUnit } from 'effector-react';
import { $store } from 'models/store';

const getDisableIndex = (data: ISession[]) => {
	if (data?.length > 0) {
		const lastSession = data[0];

		if (lastSession?.end === null) {
			return true;
		}
	}
	return false;
};

function Home() {
	const { get, error } = useFetch();

	const [sessions, setSessions] = React.useState<ISession[]>([]);
	const [disable, setDisable] = React.useState(false);

	const user = useUnit($user);
	const store = useUnit($store);
	const navigate = useNavigate();
	const [accept, setAccept] = useLocalStorage<boolean | undefined>('accept', undefined);
	const { keycloak, initialized } = useKeycloak();

	const handleShopYes = () => {
		navigate('/qrcode');
	};

	const handleExit = () => {
		delToken();
		keycloak?.logout();
	};

	const load = React.useCallback(async () => {
		if (user) {
			const currentDate = moment().format('DD.MM.YYYY');
			const previousDate = moment().subtract(1, 'days').format('DD.MM.YYYY');
			const result = (await get(`/api/session/findAllByUser?beginAt=${previousDate}`)) || [];
			const data = result.filter(
				(item: ISession) =>
					!item.endId || (item.endId && moment(item.end?.createdAt).format('DD.MM.YYYY') === currentDate),
			);

			setDisable(getDisableIndex(data));
			if (data?.length > 0) {
				setSessions(data);
			}
		}
	}, [get, setSessions, setDisable, user]);

	React.useEffect(() => {
		load();
	}, [load]);

	if (
		user &&
		Array.isArray(user.groups) &&
		(user.groups.indexOf(GroupsEnum.CLIENT) > -1 || user.groups.indexOf(GroupsEnum.ADMIN) > -1)
	) {
		if (!accept) {
			return <AgreementUser onAccept={() => setAccept(true)} onCancel={handleExit} user={user} />;
		}
		if (accept) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Sessions data={sessions} disable={disable} />
				</div>
			);
		}
	}

	return (
		<Border>
			<div>
				{error && <p>{error.message}</p>}
				{store ? (
					<div className={styles.agreement}>
						<img src={WelcomeShopIcon} />
						<p>ВАШ МАГАЗИН</p>
						<h2>{store.title}? </h2>
						<Button variant="green" type="button" onClick={handleShopYes} size="lg">
							ДА
						</Button>
						<a onClick={handleExit} className={styles['agreement-text-exit']} href="#">
							Нет, ошибка
						</a>
					</div>
				) : (
					<div style={{ height: '100vh' }}>
						<div style={{ position: 'absolute', top: '50%', transform: 'translateY(-100%)' }}>
							<div className="row">
								<div className="column-xs-12" style={{ textAlign: 'center' }}>
									Вы не связаны ни с одним магазином
								</div>
								<div className="column-xs-12">
									{' '}
									<Button variant="green" size="lg" onClick={handleExit} style={{ margin: 'auto' }}>
										Выйти
									</Button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</Border>
	);
}

export default Home;
