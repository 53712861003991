import React from 'react';
import styles from './styles.module.scss';

interface PopupProps {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, children }) => {
	if (!isOpen) return null;

	const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			onClose();
		}
	};

	return (
		<div className={styles['popup']} onClick={handleOverlayClick}>
			<div className={styles['popup-content']}>
				<span className={styles['close']} onClick={onClose}>
					&times;
				</span>
				{children}
			</div>
		</div>
	);
};

export default Popup;
