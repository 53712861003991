import React from 'react';
import moment from 'moment';
import useFetch from 'use-http';
import styles from './styles.module.scss';
import GroupIcon from 'assets/img/pic/Group.png';
import ArrowBackIcon from 'assets/img/svg/arrow-back.svg';
import { useNavigate } from 'react-router-dom';
import { ISession } from 'types/session';
import cn from 'classnames';

export const Card = ({ session }: { session: ISession }) => {
	return (
		<div className="column-md-12">
			<div className={styles['history']}>
				<div className={styles['history-left-side']}>
					<img src={GroupIcon} />
				</div>
				<div className={styles['history-right-side']}>
					<div className={styles['history-title']}>
						{session?.store?.number} {session?.store?.title}
					</div>
					<div className={styles['history-time']}>
						{session?.begin && moment(session.begin.createdAt).format('HH:mm')} -{' '}
						{session?.end && moment(session.end.createdAt).format('HH:mm')}
					</div>
					<div className={styles['history-date']}>
						{session?.begin && moment(session.begin.createdAt).format('DD.MM.YYYY')}
					</div>
				</div>
			</div>
		</div>
	);
};

export default function History() {
	const { get } = useFetch();

	const [sessions, setSessions] = React.useState([]);

	const navigate = useNavigate();

	const load = React.useCallback(async () => {
		const previousDate = moment().subtract(1, 'days').format('DD.MM.YYYY');
		const result = await get(`/api/session/findAllByUser?beginAt=${previousDate}`);
		const data = result.filter((item: ISession) => item.endId);
		setSessions(data);
	}, [get, setSessions]);

	React.useEffect(() => {
		load();
	}, [load]);

	return (
		<div style={{ textAlign: 'center' }}>
			<div className={styles['wrap']}>
				<div className={cn(styles['top-side'], styles['top-side--second'])}>
					<h2 className={styles['top-side-left']}>История сессий</h2>
					<div className={styles['counter']}>
						<div className={cn(styles['counter-value'], styles['counter-value--black'])}>{sessions?.length}</div>
						<div className={cn(styles['counter-label'], styles['counter-label--black'])}>Завершено</div>
					</div>
				</div>
				<div className={styles['body-side']}>
					<div className="row">
						{sessions?.map((session: ISession) => (
							<Card key={session.id} session={session} />
						))}
					</div>
				</div>
				<div className={styles['bottom-side']}>
					<div className={styles['navigate']}>
						<div className={styles['navigate-page']}>
							<img width="10" src={ArrowBackIcon} style={{ display: 'initial' }} />{' '}
							<span onClick={() => navigate('/')}>Сегодня</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
