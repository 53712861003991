import React from 'react';
import Button from 'components/UI/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { $store, $storeList } from 'models/store';
import { useUnit } from 'effector-react';
import { IStore } from 'dto/store.dto';
import { setAnotherStore } from 'models/user';

export default function Section() {
	const navigate = useNavigate();

	const store = useUnit($store);
	const storeList = useUnit($storeList);

	const location = useLocation();
	const fromHomePage = location.state?.fromHomePage;	

	const isHomePage = fromHomePage === '/';

	const selectStore = React.useCallback((storeId: string) => {
		navigate(`confirm/${storeId}`);
	}, []);

	const handleBack = React.useCallback(() => {
		navigate('/qrcode');
	}, []);

	const ExtraWork = React.useCallback(() => {
		setAnotherStore(true);
		navigate('/');
	}, []);

	return (
		<div className={styles['section-list']}>
			<h2>Выберите секцию</h2>
			{storeList.map((item: IStore, index, self) => {
				return (
					index === self.findIndex(t => t.id === item.id) && (
						<div key={item.id} className={styles['section-item']}>
							<Button variant="violet" size="lg" block onClick={() => selectStore(item.id)}>
								{item.number} {item.title}
							</Button>
						</div>
					)
				);
			})}
			{isHomePage && (
				<div className={styles["section-item"]}><Button variant="green" block onClick={ExtraWork} size="lg">
					Другой магазин (подработка)
				</Button></div>
			)}			
			{store && (
				<Button variant="green" onClick={handleBack}>
					Назад
				</Button>
			)}
		</div>
	);
}
