import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';
import Paginate from 'components/UI/Paginate';
import Table, { TableFilter } from 'components/UI/Table';
import usePaginate from 'hooks/usePaginate';
import moment from 'moment-timezone';
import { ISession } from 'types/session';
import { convertUser, convertUserFilter } from 'utils/helpers';
import { ISelectOptions } from 'types/utils';
import AsyncSelect from 'components/UI/AsyncSelect';
import Button from 'components/UI/Button';
import DatePicker from 'react-datepicker';
import ZoomComponent from 'components/UI/Zoom';
import { useUnit } from 'effector-react';
import { $storeStr } from 'models/store';
import Select from 'components/UI/Select';
import { IStores } from 'types/stores';
import FileDownload from 'assets/img/svg/download.svg';

const perPage = 10;
const host = process.env.REACT_APP_BACKEND_URL;

export default function Sessions() {
	const { storeId } = useParams();
	const { patch, get, loading, error } = useFetch(host);

	const [data, setData] = React.useState([]);
	const [userList, setUserList] = React.useState([]);
	const [storesList, setStoresList] = React.useState<IStores[]>([]);
	const [sortKey, setSortKey] = React.useState('');
	const [sortDirection, setSortDirection] = React.useState('asc');
	const [storeTitle, setStoreTitle] = React.useState('');
	const [storeNumber, setStoreNumber] = React.useState('');
	const [registrationTypeOptions, setRegistrationTypeOptions] = React.useState([]);
	const [regionalManagersOptions, setRegionalManagersOptions] = React.useState([]);
	const [aoManagersOptions, setaoManagersOptions] = React.useState([]);
	const [filter, setFilter] = React.useState<TableFilter>(new TableFilter());
	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);
	const storeStr = useUnit($storeStr);

	const currentStore = React.useMemo(() => {
		return storesList?.find((store: IStores) => store.id === storeId) || null;
	}, [storesList, storeId]);

	const load = async () => {
		const params = Object.entries(filter)
			.map(item => item.join('='))
			.join('&');
		console.log('Параметры запроса:', params);
		const result = await get(`/api/session?offset=${offset}&limit=${perPage}&storeId=${storeId}&${params}`);

		if (result?.rows) {
			console.log('Данные о сессиях: ', result.rows);
			setData(result.rows.map((row: any) => ({ ...row, uri: row?.preview?.uri ? row.preview.uri : null })));
			setCount(result.count);
		}
	};

	React.useEffect(() => {
		init();
	}, [storeId]);

	async function init() {
		const resultUser = await get(`/api/users`);
		console.log('Пользователи:', resultUser);
		const resultSessions = await get('/api/session');
		console.log('Сессии', resultSessions);
		const resultStores = await get('/api/stores');
		const usersFio = await get(`/api/session/usersByStore?${storeId ? `storeId=${storeId}` : ''}`);

		console.log('Полученные магазины:', resultStores);

		if (resultSessions.rows) {
			const formattedOptions: any = [];

			resultSessions.rows.forEach((session: any) => {
				if (session.begin) {
					const type = session.begin.type;

					if (!formattedOptions.some((option: { value: any }) => option.value === type)) {
						formattedOptions.push({
							value: type,
							label: type,
						});
					}
				}
			});

			const regionalManagers = resultStores.rows
				.filter((store: { regional_manager: string }) => store.regional_manager)
				.map((store: { id: string; regional_manager: string }) => ({
					value: store.regional_manager,
					label: store.regional_manager,
				}));

			const aoManagers = resultStores.rows
				.filter((store: { ao_manager: string }) => store.ao_manager)
				.map((store: { id: string; ao_manager: string }) => ({
					value: store.ao_manager,
					label: store.ao_manager,
				}));

			setRegionalManagersOptions(regionalManagers);
			setaoManagersOptions(aoManagers);
			setRegistrationTypeOptions(formattedOptions);

			setStoresList(resultStores.rows);
		}

		setUserList(usersFio);
	}

	React.useEffect(() => {
		load();
	}, [offset, perPage, filter, storeId]);

	const calculateDiff = (row: ISession) => {
		if (row.begin && row.end) {
			const fromDate = moment(row.begin.createdAt);
			const toDate = moment(row.end.createdAt);
			return toDate.diff(fromDate, 'hours');
		}
		return 0;
	};

	const sortTable = (key: string, isNumeric = false, initialDirection: 'asc' | 'desc' = 'asc') => {
		const isSameKey = sortKey === key;
		const newSortDirection = isSameKey ? (sortDirection === 'asc' ? 'desc' : 'asc') : initialDirection;

		setSortKey(key);
		setSortDirection(newSortDirection);

		const sortedData = [...data].sort((a: any, b: any) => {
			let valueA: any;
			let valueB: any;

			const keys = key.split('.');
			valueA = keys.reduce((obj: any, k: string) => (obj && obj[k] !== undefined ? obj[k] : ''), a);
			valueB = keys.reduce((obj: any, k: string) => (obj && obj[k] !== undefined ? obj[k] : ''), b);

			if (key === 'diff') {
				valueA = calculateDiff(a);
				valueB = calculateDiff(b);
			}

			if (isNumeric) {
				valueA = Number(valueA) || 0;
				valueB = Number(valueB) || 0;
				return newSortDirection === 'asc' ? valueA - valueB : valueB - valueA;
			} else {
				valueA = valueA ? valueA.toString().toLowerCase() : '';
				valueB = valueB ? valueB.toString().toLowerCase() : '';
				return newSortDirection === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
			}
		});

		setData(sortedData);
	};

	async function loadUser(inputValue: string, callback: (options: ISelectOptions[]) => void) {
		let data = [];
		const params = new URLSearchParams({
			fio: inputValue,
			storeId: storeId || '',
		});
		if (inputValue) {
			data = await get(`/api/session/usersByStore?${params.toString()}`);
		}

		return convertUser(data);
	}

	const headers = [
		{
			value: 'position',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Должность</span>
					<button onClick={() => sortTable('user.position', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('user.position', false, 'desc')}>↓</button>
				</div>
			),
			formatted: React.useCallback((row: any) => {
				return <div>{row.user?.outsourcing?.pos.title}</div>;
			}, []),
		},
		{
			value: 'name',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>ФИО</span>
					<button onClick={() => sortTable('user.secondname', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('user.secondname', false, 'desc')}>↓</button>
				</div>
			),
			formatted: React.useCallback((row: any) => {
				return (
					<div>
						<span>{row.user?.secondname} </span>
						<span>{row.user?.firstname} </span>
						<span>{row.user?.middlename} </span>
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					console.log(newValue);
					setFilter({ ...filter, userId: newValue });
				};

				return (
					<AsyncSelect
						width="200px"
						onChange={handleChange}
						load={loadUser}
						isClearable
						defaultOptions={convertUserFilter(userList)}
					/>
				);
			}, [filter, setFilter, userList]),
		},
		{
			value: 'startAt',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Время прихода</span>
					<button onClick={() => sortTable('begin.createdAt', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('begin.createdAt', false, 'desc')}>↓</button>
				</div>
			),
			width: '100px',
			formatted: React.useCallback((row: ISession) => {
				return <div>{row.begin && moment(row.begin.createdAt).format('DD.MM.YY HH:mm')}</div>;
			}, []),
			filter: function Filter() {
				const [startDate, setStartDate] = React.useState(new Date());
				const [endDate, setEndDate] = React.useState();
				const onChange = (dates: any) => {
					const [start, end] = dates;
					setStartDate(start);
					setEndDate(end);
					setFilter({ ...filter, fromDate: start, toDate: end });
				};

				type ButtonProps = React.HTMLProps<HTMLButtonElement>;

				const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(function Render(
					{ value, onClick },
					ref,
				) {
					return <Button onClick={onClick}>{value}</Button>;
				});

				return (
					<DatePicker
						selected={startDate}
						onChange={onChange}
						startDate={startDate}
						endDate={endDate}
						customInput={<ExampleCustomInput />}
						selectsRange
					/>
				);
			},
		},
		{
			value: 'leftAt',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Время ухода</span>
					<button onClick={() => sortTable('end.createdAt', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('end.createdAt', false, 'desc')}>↓</button>
				</div>
			),
			width: '100px',
			formatted: React.useCallback((row: ISession) => {
				return <div>{row.end && moment(row.end.createdAt).format('DD.MM.YY HH:mm')}</div>;
			}, []),
			filter: React.useCallback(() => {
				console.log('Фильтр', filter);
				const params = Object.entries(filter)
					.map(item => item.join('='))
					.join('&');
				console.log('Параметры запроса: ', params);

				return (
					<Button
						onClick={() => {
							window.location.href = host + `/api/session/exportToXls?storeId=${storeId}&${params}`;
						}}
						variant="secondary"
						size="sm"
						endIcon={
							<i>
								<img
									src={FileDownload}
									style={{
										filter: 'invert(22%) sepia(37%) saturate(900%) hue-rotate(193deg) brightness(90%) contrast(83%)',
									}}
								/>
							</i>
						}
					>
						Скачать xls
					</Button>
				);
			}, [filter, setFilter]),
		},
		{
			value: 'startAtMscTime',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Время прихода МСК</span>
					<button onClick={() => sortTable('begin.createdAt', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('begin.createdAt', false, 'desc')}>↓</button>
				</div>
			),
			width: '100px',
			formatted: React.useCallback((row: ISession) => {
				return <div>{row.begin && moment.tz(row.begin.createdAt, 'Europe/Moscow').format('DD.MM.YY HH:mm')}</div>;
			}, []),
		},
		{
			value: 'leftAtMscTime',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Время ухода МСК</span>
					<button onClick={() => sortTable('end.createdAt', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('end.createdAt', false, 'desc')}>↓</button>
				</div>
			),
			width: '100px',
			formatted: React.useCallback((row: ISession) => {
				return <div>{row.end && moment.tz(row.end.createdAt, 'Europe/Moscow').format('DD.MM.YY HH:mm')}</div>;
			}, []),
		},
		{
			value: 'diff',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Сумма часов</span>
					<button onClick={() => sortTable('diff', true, 'asc')}>↑</button>
					<button onClick={() => sortTable('diff', true, 'desc')}>↓</button>
				</div>
			),
			formatted: React.useCallback((row: ISession) => {
				let diff = 0;
				if (row.begin && row.end) {
					const fromDate = moment(row.begin.createdAt);
					const toDate = moment(row.end.createdAt);
					diff = toDate.diff(fromDate, 'hours');
				}
				return <div>{diff}</div>;
			}, []),
		},
		{
			value: 'type',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Тип регистрации</span>
					<button onClick={() => sortTable('begin.type', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('begin.type', false, 'desc')}>↓</button>
				</div>
			),
			formatted: React.useCallback((row: ISession) => {
				return <div>{row?.begin?.type === 'ручная' ? 'ручная' : 'автомат'}</div>;
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, type: newValue });
				};
				return <Select onChange={handleChange} options={registrationTypeOptions} isClearable />;
			}, [filter, setFilter, registrationTypeOptions]),
		},
		{
			value: 'geo',
			title: 'Геолокация (приход/уход)',
			formatted: React.useCallback((row: ISession) => {
				return (
					<div>
						{row?.begin && row?.begin?.lat && row.begin?.type === 'ручная' && (
							<a
								href={`https://geotree.ru/coordinates?lat=${row?.begin?.lat}&lon=${row?.begin?.lon}&z=18&mlat=${row?.begin?.lat}&mlon=${row?.begin?.lon}`}
								target="_black"
							>
								Геотег1
							</a>
						)}
						{row?.end && row?.end?.lat && row.begin?.type === 'ручная' && (
							<a
								href={`https://geotree.ru/coordinates?lat=${row?.end?.lat}&lon=${row?.end?.lon}&z=18&mlat=${row?.end?.lat}&mlon=${row?.end?.lon}`}
								target="_black"
							>
								{' '}
								Геотег2
							</a>
						)}
					</div>
				);
			}, []),
		},
		{
			value: 'photo',
			title: 'Фото',
			width: '200px',
			formatted: React.useCallback((row: ISession) => {
				return (
					<div>
						{row.begin && row.begin?.file && <ZoomComponent src={host + row.begin?.file?.uri} />}
						{row.end && row.end?.file && <ZoomComponent src={host + row.end?.file?.uri} />}
					</div>
				);
			}, []),
		},
		{
			value: 'regional-director',
			title: 'Закрепленный РД',
			width: '150px',
			formatted: (row: ISession) => {
				return <div>{row.store?.regional_manager}</div>;
			},
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, regional_manager: newValue });
				};

				return <Select onChange={handleChange} options={regionalManagersOptions} isClearable />;
			}, [filter, setFilter, regionalManagersOptions]),
		},
		{
			value: 'administrative-department',
			title: 'Закрепленный АО',
			width: '150px',
			formatted: (row: ISession) => {
				return <div>{row.store?.ao_manager}</div>;
			},
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, ao_manager: newValue });
				};

				return <Select onChange={handleChange} options={aoManagersOptions} isClearable />;
			}, [filter, setFilter, aoManagersOptions]),
		},
	];

	console.log({ userList });
	return (
		<div>
			{error && 'Error!'}
			<div style={{ display: 'flex', width: '300px' }}>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '5px' }}>
					<p style={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: 'bold' }}>
						Номер секции: {currentStore?.number || ''}
					</p>
					<p style={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: 'bold' }}>
						Название секции: {currentStore?.title || ''}
					</p>
				</div>
			</div>
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
