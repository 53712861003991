import React from 'react';
import useFetch from 'use-http';
import Paginate from 'components/UI/Paginate';
import usePaginate from 'hooks/usePaginate';
import Table, { TableFilter } from 'components/UI/Table';
import Button from 'components/UI/Button';
import moment from 'moment-timezone';
import { BreachEnum, IBreach } from 'types/breach';
import Select from 'components/UI/Select';
import AsyncSelect from 'components/UI/AsyncSelect';
import { ISelectOptions } from 'types/utils';
import { convertUser } from 'utils/helpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FileDownload from 'assets/img/svg/download.svg';
import { useUnit } from 'effector-react';
import { $store } from 'models/store';
import { $user } from 'models/user';
import { GroupsEnum } from 'types/users';
import { ISession } from 'types/session';
import ZoomComponent from 'components/UI/Zoom';

const perPage = 10;

const host = process.env.REACT_APP_BACKEND_URL;

export default function PhotoViolations() {
	const store = useUnit($store);
	const user = useUnit($user);
	const { patch, get, loading, error } = useFetch();

	const [data, setData] = React.useState([]);
	const [userList, setUserList] = React.useState([]);
	const [storeIdOptions, setStoreIdOptions] = React.useState([]);
	const [regionalManagersOptions, setRegionalManagersOptions] = React.useState([]);
	const [aoManagersOptions, setaoManagersOptions] = React.useState([]);
	const [sortKey, setSortKey] = React.useState('');
	const [sortDirection, setSortDirection] = React.useState('asc');
	const [filter, setFilter] = React.useState<TableFilter>(new TableFilter());
	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);

	const sortTable = (key: string, isNumeric = false, initialDirection: 'asc' | 'desc' = 'asc') => {
		const isSameKey = sortKey === key;
		const newSortDirection = isSameKey ? (sortDirection === 'asc' ? 'desc' : 'asc') : initialDirection;

		setSortKey(key);
		setSortDirection(newSortDirection);

		const sortedData = [...data].sort((a: IBreach, b: IBreach) => {
			let valueA: any;
			let valueB: any;

			const keys = key.split('.');
			valueA = keys.reduce((obj: any, k: string) => (obj && obj[k] !== undefined ? obj[k] : ''), a);
			valueB = keys.reduce((obj: any, k: string) => (obj && obj[k] !== undefined ? obj[k] : ''), b);

			if (key === 'open') {
				return newSortDirection === 'asc'
					? valueA === valueB
						? 0
						: valueA
						? 1
						: -1
					: valueA === valueB
					? 0
					: valueA
					? -1
					: 1;
			}

			if (isNumeric) {
				valueA = Number(valueA) || 0;
				valueB = Number(valueB) || 0;
				return newSortDirection === 'asc' ? valueA - valueB : valueB - valueA;
			} else {
				valueA = valueA ? valueA.toString().toLowerCase() : '';
				valueB = valueB ? valueB.toString().toLowerCase() : '';
				return newSortDirection === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
			}
		});

		setData(sortedData);
	};

	async function load(filter = '') {
		let url = `/api/breach?offset=${offset}&limit=${perPage}`;

		let storeId = null;

		if (user && user.groups && user.groups.indexOf(GroupsEnum.ADMIN) > -1) {
			storeId = '';
		} else if (user?.groups?.filter(group => group.startsWith('/rdaccess/rdaccess.'))) {
			storeId = '';
		} else if (store?.id) {
			storeId = store?.id;
		}

		if (storeId !== null) {
			if (storeId) {
				url += `&storeId=${storeId}`;
			}
			url += `&${filter}`;
			console.log('Request URL:', url);

			const result = await get(url);
			console.log('Записи:', result);
			if (result?.rows) {
				const sortedData = result.rows.sort((a: any, b: any) => {
					const numberA = a.checkin?.store?.number || 0;
					const numberB = b.checkin?.store?.number || 0;
					return numberA - numberB;
				});
				setData(sortedData);
				setCount(result.count);
			}
		}
	}

	React.useEffect(() => {
		init();
	}, []);

	async function init() {
		const resultUser = await get(`/api/users`);
		setUserList(resultUser);

		const resultStores = await get(`api/stores`);
		if (resultStores.rows) {
			const formattedStores = resultStores.rows.map((store: { id: any; number: any }) => ({
				value: store.id,
				label: store.number,
			}));

			const regionalManagers = resultStores.rows
				.filter((store: { regional_manager: any }) => store.regional_manager)
				.map((store: { regional_manager: any }) => ({
					value: store.regional_manager,
					label: store.regional_manager,
				}));

			const aoManagers = resultStores.rows
				.filter((store: { ao_manager: any }) => store.ao_manager)
				.map((store: { ao_manager: any }) => ({
					value: store.ao_manager,
					label: store.ao_manager,
				}));

			setRegionalManagersOptions(regionalManagers);
			setaoManagersOptions(aoManagers);
			setStoreIdOptions(formattedStores);
		}
	}

	React.useEffect(() => {
		load(
			Object.entries(filter)
				.map(item => item.join('='))
				.join('&'),
		);
	}, [offset, perPage, filter, store]);

	async function loadUser(inputValue: string, callback: (options: ISelectOptions[]) => void) {
		let data = [];
		const params = new URLSearchParams({
			fio: inputValue,
		});
		if (inputValue) {
			data = await get(`/api/users?${params.toString()}`);
		}

		return convertUser(data);
	}

	const headers = [
		{
			value: 'number',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Номер магазина</span>
					<button onClick={() => sortTable('checkin.store.number', true, 'asc')}>↑</button>
					<button onClick={() => sortTable('checkin.store.number', true, 'desc')}>↓</button>
				</div>
			),
			width: '150px',
			formatted: React.useCallback((row: IBreach) => {
				return <div>{row?.checkin?.store?.number}</div>;
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: string): void => {
					const newFilter = { ...filter, storeId: newValue };
					console.log('Новый фильтр', newFilter);
					setFilter(newFilter);
				};

				return <Select onChange={handleChange} options={storeIdOptions} isClearable defaultValue={filter.storeId} />;
			}, [filter, setFilter, storeIdOptions]),
		},
		{
			value: 'title',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Название магазина</span>
					<button onClick={() => sortTable('checkin.store.title', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('checkin.store.title', false, 'desc')}>↓</button>
				</div>
			),
			width: '300px',
			formatted: React.useCallback((row: IBreach) => {
				return <div>{row?.checkin?.store?.title}</div>;
			}, []),
		},
		{
			value: 'position',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Должность</span>
					<button onClick={() => sortTable('user.position', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('user.position', false, 'desc')}>↓</button>
				</div>
			),
			formatted: React.useCallback((row: any) => {
				return <div>{row.user?.outsourcing?.pos.title}</div>;
			}, []),
		},
		{
			value: 'name',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>ФИО</span>
					<button onClick={() => sortTable('user.secondname', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('user.secondname', false, 'desc')}>↓</button>
				</div>
			),
			width: '500px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{row.user?.secondname} </span>
						<span>{row.user?.firstname} </span>
						<span>{row.user?.middlename} </span>
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, userId: newValue });
				};

				return (
					<AsyncSelect onChange={handleChange} load={loadUser} isClearable defaultOptions={convertUser(userList)} />
				);
			}, [filter, setFilter, userList]),
		},
		{
			value: 'time',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Время в магазине</span>
					<button onClick={() => sortTable('createdAt', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('createdAt', false, 'desc')}>↓</button>
				</div>
			),
			width: '300px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{moment(row.createdAt).format('DD.MM.YY HH:mm')}</span>
					</div>
				);
			}, []),
			filter: function Filter() {
				const [startDate, setStartDate] = React.useState(new Date());
				const [endDate, setEndDate] = React.useState();
				const onChange = (dates: any) => {
					const [start, end] = dates;
					setStartDate(start);
					setEndDate(end);
					setFilter({ ...filter, fromDate: start, toDate: end });
				};

				type ButtonProps = React.HTMLProps<HTMLButtonElement>;

				const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(function Render(
					{ value, onClick },
					ref,
				) {
					return <Button onClick={onClick}>{value}</Button>;
				});

				return (
					<DatePicker
						selected={startDate}
						onChange={onChange}
						startDate={startDate}
						endDate={endDate}
						customInput={<ExampleCustomInput />}
						selectsRange
					/>
				);
			},
		},
		{
			value: 'time_to_msk',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Время в магазине по МСК</span>
					<button onClick={() => sortTable('createdAt', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('createdAt', false, 'desc')}>↓</button>
				</div>
			),
			width: '300px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{moment.tz(row.createdAt, 'Europe/Moscow').format('DD.MM.YY HH:mm')}</span>
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const newFilter = { ...filter };
				if (!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.ADMIN) > -1) {
					/* empty */
				} else if (store?.id) {
					newFilter['storeId'] = store.id;
				}

				const params = Object.entries(newFilter)
					.map(item => item.join('='))
					.join('&');

				return (
					<Button
						onClick={() => {
							window.location.href = host + `/api/breach/reportToXls?${params}`;
						}}
						variant="secondary"
						size="sm"
						endIcon={
							<i>
								<img
									src={FileDownload}
									style={{
										filter: 'invert(22%) sepia(37%) saturate(900%) hue-rotate(193deg) brightness(90%) contrast(83%)',
									}}
								/>
							</i>
						}
					>
						Скачать xls
					</Button>
				);
			}, [filter, setFilter, store]),
		},
		{
			value: 'photo',
			title: 'Фото',
			width: '200px',
			formatted: React.useCallback((row: IBreach) => {
				if (row.type === 'RegManual' || row.type === 'ErrorGeo' || row.checkin?.outsourcing) {
					return (
						<div>
							{row.checkin && row.checkin?.file && <ZoomComponent src={host + row.checkin?.file?.uri} />}
							{row.checkin && row.checkin?.file && <ZoomComponent src={host + row.checkin?.file?.uri} />}
						</div>
					);
				}
			}, []),
		},
		{
			value: 'regional-director',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Закрепленный РД</span>
					<button onClick={() => sortTable('checkin.store.regional_manager', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('checkin.store.regional_manager', false, 'desc')}>↓</button>
				</div>
			),
			width: '150px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{row.checkin?.store?.regional_manager}</span>
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, regional_manager: newValue });
				};

				return <Select onChange={handleChange} options={regionalManagersOptions} isClearable />;
			}, [filter, setFilter, regionalManagersOptions]),
		},
		{
			value: 'administrative-department',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Закрепленный АО</span>
					<button onClick={() => sortTable('checkin.store.ao_manager', false, 'asc')}>↑</button>
					<button onClick={() => sortTable('checkin.store.ao_manager', false, 'desc')}>↓</button>
				</div>
			),
			width: '150px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{row.checkin?.store?.ao_manager}</span>
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, ao_manager: newValue });
				};

				return <Select onChange={handleChange} options={aoManagersOptions} isClearable />;
			}, [filter, setFilter, aoManagersOptions]),
		},
	];
	console.log(`Нарушения:`, data);
	return (
		<div>
			{error && 'Error!'}
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
