import React from 'react';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { Html5QrcodeResult } from 'html5-qrcode/core';
import styles from 'components/Session/styles.module.scss';

let html5QrCode: Html5Qrcode;

const qrCodeErrorCallback = (err: any) => {
	console.log(err);
};

export interface IConfig {
	fps: number;
	qrbox: { width: number; height: number };
	formatsToSupport: Html5QrcodeSupportedFormats[];
}

export interface IScaner {
	successCallback: (code: string) => void;
	mode: string | undefined;
	config: IConfig;
	facingModeUser: boolean;
}

export default function Scaner(props: IScaner) {
	const [scanner, setScanner] = React.useState<Html5Qrcode | null>(null);

	React.useEffect(() => {
		const createScanner = async () => {
			try {
				const qrCodeScanner = new Html5Qrcode('reader');
				setScanner(qrCodeScanner);
			} catch (err) {
				console.error(err);
			}
		};

		createScanner();
	}, []);

	React.useEffect(() => {
		const startScanner = async () => {
			if (scanner) {
				const qrCodeSuccessCallback = (decodedText: string, decodedResult: Html5QrcodeResult) => {
					const title =
						props.mode === 'open'
							? 'Зафиксировать время прихода в универмаг (не является временем начала смены)'
							: 'Зафиксировать время ухода из универмага (не является временем завершения смены)';
					if (window.confirm(title)) {
						props.successCallback(decodedText);
					}
				};
				try {
					const qrboxWidth = Math.max(props.config.qrbox.width, 250);
					const qrboxHeight = Math.max(props.config.qrbox.height, 250);
					props.config.qrbox.width = qrboxWidth;
					props.config.qrbox.height = qrboxHeight;

					await scanner.start(
						{ facingMode: props.facingModeUser ? 'user' : 'environment' },
						props.config,
						qrCodeSuccessCallback,
						qrCodeErrorCallback,
					);
				} catch (err) {
					let errorMessage = 'Неизвестная ошибка. Попробуйте перезагрузить страницу.';

					if (typeof err === 'string') {
						if (err.includes('NotFoundError')) {
							errorMessage = 'Устройство не найдено. Проверьте подключение камеры.';
						} else if (err.includes('NotAllowedError')) {
							errorMessage = 'Запрещен доступ к камере, просьба выдать доступ браузеру и повторить действие в системе';
						} else if (err.includes('NotReadableError')) {
							errorMessage = 'Камера занята другим приложением. Закройте другие приложения и попробуйте снова.';
						} else if (err.includes('OverconstrainedError')) {
							errorMessage = 'Камера не соответствует необходимым параметрам. Попробуйте другой тип камеры.';
						} else if (err.includes('SecurityError')) {
							errorMessage = 'Безопасность браузера не позволяет доступ к камере. Проверьте настройки безопасности.';
						} else if (err.includes('AbortError')) {
							errorMessage = 'Ошибка при запуске камеры. Попробуйте снова.';
						}
					}

					alert(errorMessage);
					console.error(err);
				}
			}
		};

		startScanner();

		return () => {
			try {
				if (scanner) {
					scanner
						.stop()
						.then(() => {
							html5QrCode.clear();
						})
						.catch(err => {
							console.log(err.message);
						});
				}
			} catch (err) {
				console.log(err);
			}
		};
	}, [scanner, props.facingModeUser]);

	return (
		<div>
			<div id="reader" className={styles.reader} />
		</div>
	);
}
