import React, { useState } from 'react';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { $store } from 'models/store';
import { useUnit } from 'effector-react';
import moment from 'moment';
import { CLOSE, ISession, OPEN, PENDING, UNSUCCESSFUL } from 'types/session';
import Table from 'components/UI/Table';
import usePaginate from 'hooks/usePaginate';
import Paginate from 'components/UI/Paginate';
import cn from 'classnames';
import Popup from 'components/Popup';
import { OutsourceRolesEnum } from 'types/users';

interface CashierData {
	cashier_login: string;
	cashier_passw: string;
}

function displayStatus(row: ISession) {
	const { begin, end, status } = row;
	switch (true) {
		case status === UNSUCCESSFUL:
			return 'Не состоялась';
		case status === PENDING:
			return 'В ожидании';
		case status === OPEN:
			return 'Открыта';
		case status === CLOSE:
			return 'Закрыта';
		default:
			return '';
	}
}

function displayPosition(row: any) {
	switch (true) {
		case !!row?.outsourcing?.pos?.title:
			return row?.outsourcing?.pos?.title;
		case !!row?.request?.position?.title:
			return row?.request?.position?.title;
		case !!row?.begin?.outsourcing?.position:
			return row?.begin?.outsourcing?.position;
		default:
			return '';
	}
}

function displayCounterParty(row: any) {
	switch (true) {
		case !!row?.outsourcing?.counterparty?.title:
			return row?.outsourcing?.counterparty?.title;
		case !!row?.begin?.outsourcing?.counterparty?.title:
			return row?.begin?.outsourcing?.counterparty?.title;
		case !!row?.request?.counterparty?.title:
			return row?.request?.counterparty?.title;
		default:
			return '';
	}
}

const perPage = 10;

function CustomButtonRow({ row, openPopup }: { row: ISession; openPopup: (row: ISession) => void }) {
	const [isDisable, setIsDisable] = useState<boolean>(false);

	const hundleClick = (row: ISession) => {
		setIsDisable(true);
		openPopup(row);
	};

	React.useEffect(() => {
		if (!isDisable) return;

		const timer = setTimeout(() => {
			setIsDisable(false);
		}, 10000);

		return () => clearTimeout(timer);
	}, [isDisable]);

	const navigate = useNavigate();
	return (
		<div key={row.id}>
			<Button
				variant="green"
				onClick={() => navigate(`accept/${row.begin.outsourcing?.id}/close/-1/${row.request ? row.id : '-1'}`)}
			>
				Закрыть смену
			</Button>
			{(row?.begin?.outsourcing?.position === OutsourceRolesEnum.Cashier ||
				row?.outsourcing?.pos?.title === OutsourceRolesEnum.Cashier) && (
				<Button disabled={isDisable} variant="violet" onClick={() => hundleClick(row)}>
					Получить логин
				</Button>
			)}
		</div>
	);
}

export default function ({ status }: { status: string[] }) {
	const navigate = useNavigate();

	const store = useUnit($store);
	const { get, post } = useFetch();
	const [sessions, setSessions] = useState([]);

	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);

	const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
	const [data, setData] = useState<CashierData[] | []>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const openPopup = async (row: ISession) => {
		setLoading(true);
		setError(null);
		try {
			const { firstname, secondname, middlename } = row.user;
			const data = {
				store: row.store?.number,
				cashier_type: '2',
				additional: [
					{
						auto_num: '',
						famname: firstname,
						name: secondname,
						surename: middlename ? middlename : '',
						company: row?.begin?.outsourcing?.counterparty?.title,
					},
				],
			};
			const response = await post('/api/outsourcing/cashierCredential', data);
			if (!response?.length) {
				throw new Error('Ошибка во время выполнения запроса');
			}
			const result: CashierData[] = response;
			setData(result);
		} catch (error) {
			setError('Ошибка во время выполнения запроса');
		} finally {
			setLoading(false);
			setIsPopupOpen(true);
		}
	};
	const closePopup = () => {
		setIsPopupOpen(false);
		setData([]);
	};

	React.useEffect(() => {
		let timer: NodeJS.Timeout;

		if (isPopupOpen) {
			timer = setTimeout(() => {
				closePopup();
			}, 30000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [isPopupOpen]);

	React.useEffect(() => {
		let timer: NodeJS.Timeout;

		if (isPopupOpen) {
			timer = setTimeout(() => {
				closePopup();
			}, 30000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [isPopupOpen]);

	const loadSession = React.useCallback(async () => {
		if (store) {
			const sessionList = await get(
				`api/session/findOutsourcing?offset=${offset}&limit=${perPage}&storeId=${store.id}&status=${status}`,
			);
			if (sessionList?.rows?.length) {
				setSessions(sessionList.rows);
				setCount(sessionList.count);
			}
		}
	}, [store, offset, status]);

	React.useEffect(() => {
		loadSession();
	}, [store, offset, perPage]);

	const headers = [
		{
			value: 'id',
			hiddeh: true,
		},
		{
			value: 'beginId',
			title: 'Время прихода',
			width: '100px',
			formatted: React.useCallback((row: any) => {
				return <div>{row.begin && moment(row.begin.createdAt).format('DD.MM HH:mm')}</div>;
			}, []),
		},
		{
			value: 'endId',
			title: 'Время ухода',
			width: '100px',
			formatted: React.useCallback((row: ISession) => {
				return <div>{row.end && moment(row.end.createdAt).format('DD.MM HH:mm')}</div>;
			}, []),
		},
		{
			value: 'outsourcePosition',
			title: 'Должность',
			width: '100px',
			formatted: React.useCallback((row: any) => {
				return <div>{displayPosition(row)}</div>;
			}, []),
		},
		{
			value: 'fio',
			title: 'ФИО',
			width: '300px',
			formatted: React.useCallback((row: any) => {
				return (
					<div>
						<span>{row.user?.firstname} </span>
						<span>{row.user?.secondname} </span>
						<span>{row.user?.middlename} </span>
					</div>
				);
			}, []),
		},
		{
			value: 'counterparty',
			title: 'Компания',
			formatted: React.useCallback((row: any) => {
				return <div>{displayCounterParty(row)}</div>;
			}, []),
		},
		{
			value: 'document',
			title: 'Номер док-та',
			formatted: React.useCallback((row: any) => {
				return (
					<div>
						{row?.user?.document_series} {row?.user?.document_number}
					</div>
				);
			}, []),
		},
		{
			value: 'status',
			title: 'Статус смены',
			formatted: React.useCallback((row: any) => {
				return <div>{displayStatus(row)}</div>;
			}, []),
		},
		{
			value: 'operation',
			title: 'Операции',
			formatted: React.useCallback((row: any) => {
				if (row.status === UNSUCCESSFUL) {
					return <div></div>;
				}
				return (
					<div>
						{row?.status === PENDING && (
							<div>
								{row.outsourcing && (
									<Button
										disabled={!(row.user?.document_number && row.user?.firstname && row.user?.secondname)}
										variant="green"
										onClick={() => navigate(`open/${row.id}`)}
									>
										Открыть смену
									</Button>
								)}
								{row.outsourcingId && (
									<Button variant="violet" onClick={() => navigate(`edit/${row.outsourcingId}`)}>
										Изменить данные аутсорсера
									</Button>
								)}
							</div>
						)}
						{row.status === OPEN && <CustomButtonRow row={row} openPopup={openPopup} />}
					</div>
				);
			}, []),
		},
	];

	return (
		<>
			<div className={styles['section-item']}>
				<Table headers={headers} rows={sessions} />
			</div>
			<div className={styles['section-item']}>
				{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
			</div>
			<Popup isOpen={isPopupOpen} onClose={closePopup}>
				{loading && <p>Загрузка...</p>}
				{error && <p>{error}</p>}
				{data && data.length > 0 ? (
					<ul>
						{data.map((item, index) => (
							<li key={index}>
								<p>
									<strong>Логин:</strong> {item.cashier_login}
								</p>
								<p>
									<strong>Пароль:</strong> {item.cashier_passw}
								</p>
							</li>
						))}
					</ul>
				) : (
					<p>Данные не найдены</p>
				)}
			</Popup>
		</>
	);
}
