import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import styles from './styles.module.scss';
import { IQRcode, IStore } from 'dto/store.dto';
import { useCheckin } from 'hooks/useCheckin';

export default function Accept() {
	const { mode, qrcode } = useParams();
	const navigate = useNavigate();
	const { checkin, loading, error } = useCheckin();
	const { get } = useFetch(`/api/qrcode/code`);

	const [storeData, setStoreData] = React.useState<IStore | undefined>();

	React.useEffect(() => {
		const fetchStoreData = async () => {
			if (qrcode) {
				const result: IQRcode = await get(qrcode);
				setStoreData(result?.store);
			}
		};
		fetchStoreData();
	}, [qrcode]);

	React.useEffect(() => {
		if (error) {
			alert(error);
			navigate('/');
		}
	}, [error]);

	return (
		<div className={styles['section-list']}>
			<div className="row">
				<div className="column-xs-12">
					<h2>Подтвердите регистрацию прихода/ухода в универмаге</h2>
					<h2 style={{ margin: '30px 0px 20px', fontSize: '30px' }}>
						<div className={styles['h2-title']}>
							{storeData?.number} {storeData?.title}
						</div>
					</h2>
				</div>
			</div>
			<div className="row">
				<div className="column-xs-6">
					<Button
						variant="violet"
						type="button"
						onClick={() => navigate(`/scanner/${mode}`)}
						style={{ margin: 'auto' }}
					>
						Назад
					</Button>
				</div>
				<div className="column-xs-6">
					<Button
						variant="green"
						disabled={loading}
						type="button"
						onClick={async () => {
							if (qrcode) await checkin(qrcode);
						}}
						style={{ margin: 'auto' }}
					>
						{loading ? 'Загрузка...' : 'Подтвердить'}
					</Button>
				</div>
			</div>
		</div>
	);
}
