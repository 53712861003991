import React from 'react';
import { useFetch } from 'use-http';
import { useParams, useNavigate } from 'react-router-dom';
import Button from 'components/UI/Button';
import { BreachEnum } from 'types/breach';
import Camera from 'components/Camera';
import { imagebase64 } from 'utils/imagebase64';
import { useUnit } from 'effector-react';
import { $coordinate, $token } from 'models/user';
import { registrCheckin } from 'utils/helpers';

function dataURItoBlob(dataURI: string) {
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);

	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}

function Photo() {
	const { code } = useParams();
	const { post, error } = useFetch();
	const token = useUnit($token);
	const coordinate = useUnit($coordinate);
	const [facingMode, setFacingMode] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [localError, setLocalError] = React.useState<string | null>(null);

	const handleClick = React.useCallback(() => {
		setFacingMode(!facingMode);
	}, [setFacingMode, facingMode]);

	const navigate = useNavigate();

	const [image, setImage] = React.useState('');

	function handleTakePhoto(dataUri: string) {
		setImage(dataUri);
	}

	const handleSave = React.useCallback(async () => {
		const fetchData = async () => {
			setLoading(true);
			setLocalError(null);

			try {
				const file = dataURItoBlob(image);
				const body = new FormData();
				body.append('file', file, 'image.jpg');
				const imageRes = await post('api/file/download', body);
				if (imageRes?.statusCode) {
					throw new Error(imageRes.message || 'Ошибка при сохранении изображения сотрудника')
				}

				if (imageRes) {
					const checkinRes = await registrCheckin(post, code, token, coordinate, imageRes.id);
					if (checkinRes) {
						const data = {
							data: { fileId: imageRes.id, uri: imageRes.uri },
							type: BreachEnum.ErrorGeo,
							checkinId: checkinRes.id,
						};
						const breachRes = await post('/api/breach', data);
						if (breachRes?.statusCode) {
							throw new Error(breachRes.message || 'Ошибка при регистрации ошибки в ЭЖ')
						}
					}
				}

				navigate('/');
			} catch (err) {
				if (err instanceof Error) {
					setLocalError(err.message);
				} else {
					setLocalError('Неизвестная ошибка');
				}
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [image, navigate, post, code, coordinate]);

	React.useEffect(() => {
		if (localError || error) {
			alert(localError || error?.message);
			navigate('/');
		}
	}, [localError, error]);

	return (
		<div className="wrap">
			{loading && <div>Загрузка...</div>}
			{image ? (
				<div>
					<div className="row">
						<div className="column-xs-12">
							<img alt="img" src={image} style={{ width: 'auto' }} />
						</div>
					</div>
					<div className="row">
						<div className="column-xs-6">
							<Button variant="green" type="button" onClick={() => setImage('')} style={{ margin: 'auto' }}>
								Повторить
							</Button>
						</div>
						<div className="column-xs-6">
							<Button variant="green" type="button" onClick={() => handleSave()} style={{ margin: 'auto' }}>
								Отправить
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div>
					<Button type="button" onClick={handleClick} style={{ margin: '10px auto' }}>
						Смена камеры
					</Button>
					<Camera handleTakePhoto={handleTakePhoto} facingMode={facingMode} />
					<p>Сделайте селфи с QR во входной зоне универмага (перед вывеской Фамилия)</p>
				</div>
			)}
		</div>
	);
}

export default Photo;
