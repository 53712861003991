import moment from 'moment';
import Button from 'components/UI/Button';
import QRCodeIcon from 'assets/img/pic/qrcode-icon.png';
import ArrowIcon from 'assets/img/svg/arrow.svg';
import { useNavigate } from 'react-router-dom';
import { ISession } from 'types/session';
import Avatar from 'assets/img/svg/avatar.svg';
import classes from '../AdminLayout/Header/styles.module.scss';
import styles from './styles.module.scss';
import Topbar from '../AdminLayout/Topbar';
import ProfileMenu from '../AdminLayout/Topbar/ProfileMenu';
import cn from 'classnames';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';

export const Card = ({ session, variant = '' }: { session?: ISession; variant: string }) => {
	const navigate = useNavigate();

	return (
		<div className="column-xs-12">
			<div className={`${styles.card} ${styles[`card--${variant}`]}`}>
				<div className={styles['card-left-side']}>
					{session ? (
						<h2>
							{session?.begin?.timezoneOffset
								? moment(session.begin?.createdAt)
										.utcOffset(-1 * session.begin?.timezoneOffset)
										.format('HH:mm')
								: moment(session.begin?.createdAt).format('HH:mm')}
							{' - '}
							{session.end ? (
								<>
									{session.end.timezoneOffset
										? moment(session.end.createdAt)
												.utcOffset(-1 * session.end.timezoneOffset)
												.format('HH:mm')
										: moment(session.end.createdAt).format('HH:mm')}
								</>
							) : (
								'...'
							)}
						</h2>
					) : (
						<h2>Нет активных сессий</h2>
					)}

					{session ? (
						<p>
							{session?.store?.number} {session?.store?.title}
						</p>
					) : (
						<p>Нажмите на значок “QR” ниже, чтобы открыть сессию</p>
					)}
				</div>
				<div className={styles['card-right-side']}>
					{variant === 'open' && (
						<>
							<span className={styles['card-mode--text']}>Активна</span>
							<Button size="sm" variant="orange" onClick={() => navigate('/scanner/close')}>
								Закрыть
							</Button>
						</>
					)}
					{variant === 'close' && <span className={styles['card-mode--text card-mode--text-close']}>Закрыта</span>}
				</div>
			</div>
		</div>
	);
};

export default function Session({ data, disable }: { data: ISession[]; disable: boolean }) {
	const navigate = useNavigate();
	const user = useUnit($user);

	return (
		<div className={styles['wrap']}>
			<div className={styles['top-side']}>
				<h2 className={styles['top-side-left']}>Сессии сегодня</h2>
				<div className={styles['counter']}>
					<div className={styles['counter-value']}>{data.length}</div>
					<div className={styles['counter-label']}>Открытых</div>
				</div>
				<div className={styles['top-side-right']}>
					<div className={classes.header__bar}>
						<div className={classes['header__bar-item']}>
							<div className={`${classes['profile-bar']} profile-bar`}>
								<div className={classes['profile-bar__wrap']}>
									<div className={classes['profile-bar__main']}>
										<div className={classes['profile-bar__name']}>{user?.firstname}</div>
										<div className={classes['profile-bar__sub']}>{user?.secondname}</div>
									</div>
									<div className={classes['profile-bar__avatar']}>
										<div className={cn(classes.avatar, classes['avatar--md'])}>
											<img width="50px" src={Avatar} alt="" />
										</div>
									</div>
								</div>
								<Topbar>
									<ProfileMenu />
								</Topbar>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles['body-side']}>
				<div className="row">
					{data?.length > 0 ? (
						data.map((session: ISession) => {
							return <Card key={session.id} session={session} variant={session.endId ? 'close' : 'open'} />;
						})
					) : (
						<Card variant="empty" />
					)}
				</div>
			</div>
			<div className={styles['bottom-side']}>
				<div className={styles['navigate']}>
					<div className={styles['navigate-page']}>
						<span onClick={() => navigate('/history')}>История</span>{' '}
						<img width="10" style={{ display: 'initial' }} src={ArrowIcon} />
					</div>
					<div className={styles['navigate-qrcode']}>
						<img
							className={disable ? styles['disabled'] : ''}
							src={QRCodeIcon}
							onClick={!disable ? () => navigate('/scanner/open') : () => ({})}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
