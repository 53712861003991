import React from 'react';
import styles from './styles.module.scss';
import OutsTable from './OutsTable';
import { useNavigate, useParams } from 'react-router-dom';
import { $qrcode, $store, setQRCode } from 'models/store';
import { useUnit } from 'effector-react';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { IOutsourcing } from 'types/outsourcing';
import { ISession } from 'types/session';
import SessionTable from './SessionTable';

export default function AcceptCheckin() {
	const { outsourceId, mode, imageId, sessionId } = useParams();
	const navigate = useNavigate();
	const store = useUnit($store);
	const qrcode = useUnit($qrcode);

	const { get: fetchData1, loading: loading1 } = useFetch(`/api/session`);
	const { get: fetchData2, loading: loading2 } = useFetch(`/api/qrcode`);
	const { get: fetchData3, loading: loading3 } = useFetch(`/api/outsourcing`);
	const { post: fetchData4, loading: loading4 } = useFetch(`/api/checkin`);

	const [session, setSession] = React.useState<ISession | undefined>();

	const isLoading = loading1 || loading2 || loading3 || loading4;

	React.useEffect(() => {
		init();
	}, [sessionId]);

	async function init() {
		if (sessionId) {
			const result = await fetchData1(sessionId);
			setSession(result);
		}
	}

	const getCode = React.useCallback(async () => {
		if (store) {
			const qrcodeData = await fetchData2(`byStoreId?storeId=${store.id}`);
			setQRCode(qrcodeData.code);
			return qrcodeData;
		}
	}, [store, setQRCode]);

	React.useEffect(() => {
		if (!qrcode && store) {
			getCode();
		}
	}, [qrcode, store]);

	const [outsource, setOutsource] = React.useState<IOutsourcing | undefined>();

	const loadOutsource = React.useCallback(async () => {
		const outsourceRes = await fetchData3(outsourceId);
		setOutsource(outsourceRes);
	}, [outsourceId]);

	React.useEffect(() => {
		loadOutsource();
	}, [outsourceId]);

	const handleSave = React.useCallback(async () => {
		const currentDate = new Date();
		if (outsource) {
			const checkin = await fetchData4(`registrAnother`, {
				code: qrcode,
				userId: outsource?.user?.id,
				fileId: imageId !== '-1' ? imageId : null,
				outsourcingId: outsourceId,
				type: 'outsource',
				datetimeStr: `${currentDate}`,
				timezoneOffset: currentDate.getTimezoneOffset(),
				sessionId: sessionId,
				mode,
			});
		}
		navigate('/outsourcing');
	}, [qrcode, imageId, outsource]);

	const date = new Date();

	return (
		<div className={styles['section-list']}>
			<div className="row">
				<div className="column-xs-12">
					<h2>{mode === 'open' ? 'Вы хотите открыть смену сотруднику' : 'Закрыть смену?'}</h2>
					{session?.outsourcing ? <SessionTable data={session} curDateTime={date} /> : <OutsTable data={outsource} />}
				</div>
			</div>
			<div className="row">
				<div className="column-xs-4">
					<Button variant="violet" type="button" onClick={() => navigate('/qrcode')} style={{ margin: 'auto' }}>
						Назад
					</Button>
				</div>
				<div className="column-xs-4">
					<Button variant="green" disabled={isLoading} type="button" onClick={handleSave} style={{ margin: 'auto' }}>
						{isLoading ? 'Загрузка...' : 'Подтвердить'}
					</Button>
				</div>
			</div>
		</div>
	);
}
