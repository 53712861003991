import React from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { ISession } from 'types/session';
import SessionTable from './SessionTable';
import ExclamationMarkIcon from 'assets/img/pic/exclamation-mark-96.png';

export default function OpenShift() {
	const navigate = useNavigate();
	const { get } = useFetch();

	const { sessionId } = useParams();

	const [session, setSession] = React.useState<ISession | undefined>();
	const [existSessions, setExistSessions] = React.useState<ISession[] | []>();

	React.useEffect(() => {
		init();
	}, [sessionId]);

	async function init() {
		const result = await get(`/api/session/${sessionId}`);

		setSession(result);

		if (result?.user?.document_number) {
			const params = new URLSearchParams();
			const { document_number, document_series } = result?.user;
			if (document_number !== null) params.append('document_number', document_number);
			if (document_series !== null) params.append('document_series', document_series);

			const sessionList = await get(`/api/session/findExist?${params.toString()}`);
			setExistSessions(sessionList);
		}
	}

	const handleSubmit = React.useCallback(() => {
		navigate(`/outsourcing/photo/${session?.outsourcing?.id}/${session?.outsourcing?.user?.id}/${sessionId}`);
	}, [session]);

	return (
		<div className={styles['section-list']}>
			<div className="row">
				<div className="column-xs-12">
					<h2>Проверьте данные сотрудника</h2>
					{session?.outsourcing && <SessionTable data={session} />}
				</div>
			</div>
			<div className="row">
				<div className="column-xs-4">
					<Button variant="violet" type="button" onClick={() => navigate('/outsourcing')} style={{ margin: 'auto' }}>
						Назад
					</Button>
				</div>
				<div className="column-xs-4">
					<Button variant="green" type="button" onClick={handleSubmit} style={{ margin: 'auto' }}>
						Подтвердить
					</Button>
				</div>
			</div>
			{existSessions && existSessions?.length > 0 && (
				<div className="row">
					<div className="column-xs-12">
						<div className={styles['warning-container']}>
							<img src={ExclamationMarkIcon} className={styles['warning-img']} />
							<div className={styles['warning-text']}>
								У сотрудника уже есть открыта ранее смена, просьба ввести данные сотрудника кто пришел на смену
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
