import React from 'react';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import Paginate from 'components/UI/Paginate';
import usePaginate from 'hooks/usePaginate';
import Table, { TableFilter } from 'components/UI/Table';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { IStores } from 'types/stores';
import { useUnit } from 'effector-react';
import { $store } from 'models/store';
import { GroupsEnum } from 'types/users';
import { $user } from 'models/user';
import DatePicker from 'react-datepicker';
import FileDownload from 'assets/img/svg/download.svg';

const perPage = 10;

const host = process.env.REACT_APP_BACKEND_URL;

export default function Stores() {
	const { get, loading, error } = useFetch();
	const store = useUnit($store);
	const user = useUnit($user);

	const [data, setData] = React.useState([]);
	const [sortKey, setSortKey] = React.useState('');
	const [sortField, setSortField] = React.useState('number');
	const [sortDirection, setSortDirection] = React.useState('ASC');
	const [filter, setFilter] = React.useState<TableFilter>(new TableFilter());
	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage, {
		sortField,
		sortDirection,
	});

	const load = async (filter = '', currentOffset = offset) => {
		let storeId = null;

		if (user && user.groups?.includes(GroupsEnum.ADMIN)) {
			storeId = '';
		} else if (store?.id) {
			storeId = store.id;
		}

		if (storeId !== null) {
			const query = new URLSearchParams({
				offset: currentOffset.toString(),
				limit: perPage.toString(),
				filter: filter,
				storeId: storeId,
				sortField: sortField,
				sortDirection: sortDirection,
			}).toString();

			const result = await get(`/api/stores?${query}`);

			if (result?.rows) {
				setData(result.rows);
				setCount(result.count);
			}
		}
	};

	const sortTable = (key: string, isNumeric = false, initialDirection: 'asc' | 'desc' = 'asc') => {
		const isSameKey = sortKey === key;
		const newSortDirection = isSameKey ? (sortDirection === 'asc' ? 'desc' : 'asc') : initialDirection;

		setSortKey(key);
		setSortDirection(newSortDirection);

		const sortedData = [...data].sort((a: any, b: any) => {
			let valueA: any;
			let valueB: any;

			const keys = key.split('.');
			valueA = keys.reduce((obj: any, k: string) => (obj && obj[k] !== undefined ? obj[k] : ''), a);
			valueB = keys.reduce((obj: any, k: string) => (obj && obj[k] !== undefined ? obj[k] : ''), b);

			if (isNumeric) {
				valueA = Number(valueA) || 0;
				valueB = Number(valueB) || 0;
				return newSortDirection === 'asc' ? valueA - valueB : valueB - valueA;
			} else {
				valueA = valueA ? valueA.toString().toLowerCase() : '';
				valueB = valueB ? valueB.toString().toLowerCase() : '';
				return newSortDirection === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
			}
		});
		setData(sortedData);
	};

	const handleSort = (field: string) => {
		const newDirection = sortField === field ? (sortDirection === 'ASC' ? 'DESC' : 'ASC') : 'ASC';

		setSortField(field);
		setSortDirection(newDirection);
		load();
	};

	React.useEffect(() => {
		load();
	}, [offset, perPage, store, user, sortField, sortDirection]);

	const navigate = useNavigate();

	const headers = [
		{
			value: 'number',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Номер</span>
					<button onClick={() => handleSort('number')}>
						{sortField === 'number' ? (sortDirection === 'ASC' ? '↓' : '↑') : '⇅'}
					</button>
				</div>
			),
			filter: function Filter() {
				const [startDate, setStartDate] = React.useState(new Date());
				const [endDate, setEndDate] = React.useState();
				const onChange = (dates: any) => {
					const [start, end] = dates;
					setStartDate(start);
					setEndDate(end);
					setFilter({ ...filter, fromDate: start, toDate: end });
				};

				type ButtonProps = React.HTMLProps<HTMLButtonElement>;

				const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(function Render(
					{ value, onClick },
					ref,
				) {
					return <Button onClick={onClick}>{value}</Button>;
				});

				return (
					<DatePicker
						selected={startDate}
						onChange={onChange}
						startDate={startDate}
						endDate={endDate}
						customInput={<ExampleCustomInput />}
						selectsRange
					/>
				);
			},
		},
		{
			value: 'title',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Название</span>
					<button onClick={() => handleSort('title')}>
						{sortField === 'title' ? (sortDirection === 'ASC' ? '↓' : '↑') : '⇅'}
					</button>
				</div>
			),
			filter: React.useCallback(() => {
				console.log('Фильтр', filter);
				const params = new URLSearchParams();

				for (const [key, value] of Object.entries(filter)) {
					if (value !== undefined && value !== null) {
						params.append(key, String(value));
					}
				}

				const isAdminOrRd =
					user?.groups?.includes(GroupsEnum.ADMIN) || user?.groups?.some(group => group.startsWith('/rd'));

				if (!isAdminOrRd && store?.id) {
					params.append('storeId', store.id);
				}

				return (
					<Button
						onClick={() => {
							window.location.href = host + `/api/session/exportToXls?${params}`;
						}}
						variant="secondary"
						size="sm"
						endIcon={
							<i>
								<img
									src={FileDownload}
									style={{
										filter: 'invert(22%) sepia(37%) saturate(900%) hue-rotate(193deg) brightness(90%) contrast(83%)',
									}}
								/>
							</i>
						}
					>
						Скачать xls
					</Button>
				);
			}, [filter, setFilter]),
		},
		{
			value: 'address',
			title: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<span style={{ marginRight: '10px' }}>Адрес</span>
					<button onClick={() => handleSort('address')}>
						{sortField === 'address' ? (sortDirection === 'ASC' ? '↑' : '↓') : '⇅'}
					</button>
				</div>
			),
		},
		{ value: 'manager', title: 'Руководитель' },
		{
			value: 'operation',
			title: '',
			width: '300px',
			formatted: React.useCallback(
				(row: IStores) => {
					const routeChange = () => {
						const path = `/admin/stores/${row.id}/sessions`;
						navigate(path);
					};

					return (
						<div className="row">
							<div className="column-xs-6">
								<Button size="sm" variant="secondary" onClick={routeChange}>
									Статистика присутствия
								</Button>
							</div>
						</div>
					);
				},
				[navigate],
			),
		},
	];

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		await load(event.target.value);
	};

	return (
		<div>
			{error && 'Error!'}
			<div className="row">
				{user?.groups?.includes(GroupsEnum.ADMIN) || user?.groups?.some(group => group.startsWith('/rd')) ? (
					<div className="column-md-3">
						<Input onChange={handleChange} name="filter" label="Поиск по номеру магазина" />
					</div>
				) : null}
			</div>
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
