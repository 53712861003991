import React from 'react';
import AgreementUser from 'pages/Home/AgreementUser';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch, { CachePolicies } from 'use-http';

const options: { cachePolicy: CachePolicies; cacheTime: number } = {
	cachePolicy: CachePolicies.CACHE_FIRST,
	cacheTime: 10000,
};

const host = process.env.REACT_APP_BACKEND_URL;

export default function AcceptUser() {
	const { get, loading } = useFetch(host, options);

	const navigate = useNavigate();
	const { code } = useParams();
	const [user, setUser] = React.useState({
		firstname: '',
		secondname: '',
		middlename: '',
	});

	const load = React.useCallback(async () => {
		if (code) {
			const data = await get(`/api/users/byBarcode?barcode=${code}`);
			if (data?.id) {
				setUser({
					firstname: data['secondname'],
					middlename: data['middlename'],
					secondname: data['firstname'],
				});
			}
		}
	}, [code]);

	React.useEffect(() => {
		load();
	}, [load]);

	const handleAccept = React.useCallback(async () => {
		navigate(`/manual/photo/${code}`);
	}, [code]);

	const handleCancel = React.useCallback(async () => {
		navigate(`/manual`);
	}, []);

	return <div>{user.firstname && <AgreementUser onAccept={handleAccept} onCancel={handleCancel} user={user} />}</div>;
}
