import React from 'react';
import styles from './styles.module.scss';
import UserForm from './UserForm';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { $store } from 'models/store';
import { useUnit } from 'effector-react';

export default function AddUser() {
	const navigate = useNavigate();
	const store = useUnit($store);

	const { post: fetchData1, loading: loading1 } = useFetch(`/api/users`);
	const { post: fetchData2, loading: loading2 } = useFetch(`/api/outsourcing`);

	const handleSubmit = async (data: any) => {
		const outsUser = await fetchData1(`/outsource`, {
			firstname: data.firstname,
			secondname: data.secondname,
			middlename: data.middlename,
			document_series: data.document_series,
			document_number: data.document_number,
		});
		const userId = outsUser.id;

		const outsourceBody = {
			storeId: store?.id,
			userId,
			counterpartyId: data?.counterpartyId,
			positionId: data?.positionId,
			position: data.position,
		};

		const outsource = await fetchData2('', outsourceBody);

		return navigate(`/outsourcing/photo/${outsource.id}/${userId}`);
	};
	const isLoading = loading1 || loading2;

	return (
		<div className={styles['section-list']}>
			<h2>Заполните обязательные поля</h2>
			<UserForm onSubmit={handleSubmit} loadingData={isLoading} />
		</div>
	);
}
