import Camera, { IMAGE_TYPES, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

export default function CameraComponent({
	facingMode,
	handleTakePhoto,
}: {
	facingMode: boolean;
	handleTakePhoto: (uri: string) => void;
}) {
	return (
		<div style={{ width: 'calc(100% - 10%)', margin: 'auto' }}>
			<Camera
				imageType={IMAGE_TYPES.JPG}
				isImageMirror={false}
				idealFacingMode={facingMode ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
				onTakePhoto={(dataUri: string) => handleTakePhoto(dataUri)}
			/>
		</div>
	);
}
