import { ReactNode } from 'react';
import classes from './styles.module.scss';

export interface ITableHeader {
	value: string;
	title?: string | JSX.Element;
	width?: string;
	formatted?: (row: any) => any;
	filter?: () => ReactNode | undefined;
}

export interface ITableProps<T> {
	headers: ITableHeader[];
	rows: T[];
}

export class TableFilter {
	position?: string;
	fio?: string;
	type?: string;
	userId?: string;
	storeId?: string;
	fromDate?: Date;
	toDate?: Date;
	regional_manager?: string;
	ao_manager?: string;
}

export type filterByNumber = 'asc' | 'desc';

const generateUnique = (headers: ITableHeader[], row: any) => {
	const res = headers.reduce((acc: string, item: ITableHeader) => {
		return acc + (row[item.value] ? row[item.value] : '');
	}, '');
	return res;
};

export default function Table<T>({ headers, rows }: ITableProps<T>) {
	return (
		<div className={classes['table-overlay']}>
			<table className={classes.table}>
				<thead>
					<tr>
						{headers.map(header => (
							<th
								style={{ width: header.width ? header.width : 'auto', padding: '12px' }}
								key={`filter-${header.value}`}
							>
								<div>
									<div>{header.filter && header.filter()}</div>
								</div>
							</th>
						))}
					</tr>

					<tr>
						{headers.map(header => (
							<th style={{ width: header.width ? header.width : 'auto' }} key={`title-${header.value}`}>
								<div>
									<div>{header.title}</div>
								</div>
							</th>
						))}
					</tr>
				</thead>
				{rows.length > 0 ? (
					<tbody>
						{rows.map((row: any, rowIndex) => (
							<tr key={`row-${rowIndex}`}>
								{headers.map((header, headerIndex) => (
									<td key={`${header.value}-${rowIndex}-${headerIndex}`}>
										{header.formatted ? header.formatted(row) : row[header.value]}
									</td>
								))}
							</tr>
						))}
					</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan={headers.length} style={{ textAlign: 'center' }}>
								Записей не найдено.
							</td>
						</tr>
					</tbody>
				)}
			</table>
		</div>
	);
}
