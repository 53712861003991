import React from 'react';
import Button from 'components/UI/Button';
import { useParams, useNavigate } from 'react-router-dom';
import Scanner from 'components/Scanner';
import { Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { useCheckin } from 'hooks/useCheckin';

const qrConfig = {
	fps: 10,
	qrbox: { width: 500, height: 500 },
	formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
};

export default function ScannerPage() {
	const { mode } = useParams();
	const navigate = useNavigate();
	const { checkin, loading, error } = useCheckin();

	const successHandle = async (code: string) => {
		checkin(code, () => navigate(`/scanner/${mode}/accept/${code}`));
	};

	React.useEffect(() => {
		if (error) {
			alert(error);
			navigate('/');
		}
	}, [error]);

	return (
		<div style={{ minWidth: '250px', minHeight: '250px' }}>
			{loading ? (
				<div>Загрузка...</div>
			) : (
				<>
					<Scanner successCallback={successHandle} mode={mode} config={qrConfig} facingModeUser={false} />
					<Button variant="green" type="button" onClick={() => navigate('/')} style={{ margin: '40px auto' }}>
						Вернуться к списку смен
					</Button>

					{/* <button onClick={async () => successHandle('YF9NpXKWtr5EMo6')}>Click</button> */}
				</>
			)}
		</div>
	);
}
