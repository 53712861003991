import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { CLOSE, OPEN, PENDING } from 'types/session';
import cn from "classnames";
import Request from './Request'
import { useCleanerOnlyStores } from 'hooks/useCleanerOnlyStores';

export default function Outsourcing() {
	const navigate = useNavigate();
    const isCleanerOnlyStore = useCleanerOnlyStores();

	return (
		<div className={styles['section-list']}>
			<h2>Смены аутсорс</h2>

            <h3>Плановые заявки на сегодня</h3>
            <Request status={[PENDING]} />
			
            <h3>Открытые смены</h3>
            <div className={cn(styles["section-item"], styles["section-item-top"])}>
                <Button variant="violet" onClick={() => navigate('add')}>
                    {isCleanerOnlyStore ? 'Добавить сотрудника Уборщица' : 'Добавить сотрудника аутсорс'}
                </Button> 
            </div>
            <Request status={[OPEN,CLOSE]} />
		</div>
	);
}
