// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h2-title--dxLaM{padding:5px;width:auto;display:inline-block;margin:20px;border:3px solid red}", "",{"version":3,"sources":["webpack://./src/pages/Scanner/styles.module.scss"],"names":[],"mappings":"AAAA,iBACI,WAAA,CACA,UAAA,CACA,oBAAA,CACA,WAAA,CACA,oBAAA","sourcesContent":[".h2-title {\n    padding: 5px;\n    width: auto;\n    display: inline-block;\n    margin: 20px;\n    border: 3px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h2-title": "h2-title--dxLaM"
};
export default ___CSS_LOADER_EXPORT___;
