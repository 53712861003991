import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
// import Camera, { IMAGE_TYPES, FACING_MODES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import { imagebase64 } from 'utils/imagebase64';
import Button from 'components/UI/Button';
import Camera from 'components/Camera';
import { dataURItoBlob } from 'utils/helpers';
import styles from './styles.module.scss';

import moment from 'moment';
import { $qrcode, $store, setQRCode } from 'models/store';
import { useUnit } from 'effector-react';

export default function PhotoUser() {
	const { post, get } = useFetch();
	const navigate = useNavigate();
	const { barcode } = useParams();
	const [loading, setLoading] = React.useState(false);

	const store = useUnit($store);
	const qrcode = useUnit($qrcode);

	const getCode = React.useCallback(async () => {
		if (store) {
			const qrcodeData = await get(`/api/qrcode/byStoreId?storeId=${store.id}`);
			setQRCode(qrcodeData.code);
			return qrcodeData;
		}
	}, [store, setQRCode]);

	React.useEffect(() => {
		if (!qrcode && store) {
			getCode();
		}
	}, [qrcode, store]);

	const [facingMode, setFacingMode] = React.useState(false);

	const handleClick = React.useCallback(() => {
		setFacingMode(!facingMode);
	}, [setFacingMode, facingMode]);

	const [image, setImage] = React.useState('');

	const handleTakePhoto = React.useCallback(
		(dataUri: string) => {
			setImage(dataUri);
		},
		[setImage],
	);

	const [datetime, setDatetime] = React.useState(moment(new Date()).format('YYYY-MM-DD HH:mm'));

	const handleSave = React.useCallback(async () => {
		setLoading(true);
		const file = dataURItoBlob(image);
		const body = new FormData();
		const currentDate = new Date();
		body.append('file', file, 'image.jpg');

		const imageRes = await post('api/file/download', body);

		if (imageRes) {
			const userAnother = await get(`/api/users/byBarcode?barcode=${barcode}`);

			if (userAnother) {
				const checkin = await post('/api/checkin/registrAnother', {
					code: qrcode,
					userId: userAnother.id,
					fileId: imageRes.id,
					type: 'manual',
					datetimeStr: `${currentDate}`,
					timezoneOffset: currentDate.getTimezoneOffset(),
				});
			}
		}
		setLoading(false);
		navigate('/qrcode');
	}, [image, qrcode, barcode, datetime, setLoading]);

	return (
		<div className={styles.wrap}>
			{image ? (
				<div>
					<div className="row">
						<div className="column-xs-12">
							<img alt="img" src={image} style={{ width: 'auto' }} />
						</div>
					</div>
					<div className="row">
						<div className="column-xs-12">
							<Button variant="green" type="button" onClick={() => setImage('')} style={{ margin: 'auto' }}>
								Повторить
							</Button>
						</div>
						<div className="column-xs-12">
							<Button variant="green" disabled={loading} type="button" onClick={handleSave} style={{ margin: 'auto' }}>
								{loading ? 'Загрузка...' : 'Зарегистрировать приход/уход сотрудника вручную'}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div>
					<Button type="button" onClick={handleClick} style={{ margin: '10px auto' }}>
						Смена камеры
					</Button>
					<Camera handleTakePhoto={handleTakePhoto} facingMode={facingMode} />
					<div style={{ margin: '40px' }}>
						{/* <Input type="datetime-local" onChange={handleChange} name="date" label="Введите дату открытия/закрытия смены" defaultValue={datetime} /> */}
						<p>Дата и время регистрации прихода/ухода</p>
						<p>{datetime}</p>
						<br />
						<p>Сделайте фото сотрудника во входной зоне универмага (перед вывеской Фамилия)</p>
					</div>
				</div>
			)}
		</div>
	);
}
