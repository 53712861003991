import { useUnit } from 'effector-react';
import { $store } from 'models/store';

export const storeNumbers = process.env.REACT_APP_STORE_NUMBERS_CLEANERS_ONLY
  ? process.env.REACT_APP_STORE_NUMBERS_CLEANERS_ONLY
      .split(',')
      .map(num => parseInt(num.trim(), 10))
      .filter(num => !isNaN(num))
  : [];

export const useCleanerOnlyStores = (): boolean => {
  const store = useUnit($store);
  
  if (!store?.number) return false;
  
  return storeNumbers.includes(+store.number);
};