import { KCTokenParsed, JWTTokenParsed } from 'dto/user.dto';
import { createEffect, createStore, createEvent } from 'effector';

export interface ICoordinate {
	latitude?: number;
	longitude?: number;
}

export interface IPosition {
	createdAt: string;
	id: string;
	position_id: string;
	title: string;
	updatedAt: string;
}

export const $token = createStore<string>('');

export const $coordinate = createStore<ICoordinate | undefined>({});

export const $noCoord = createStore<boolean>(false);

export const $anotherStore = createStore<boolean>(false);

export const setToken = createEvent<string>();

export const delToken = createEvent();

export const setCoordinate = createEvent<ICoordinate | undefined>();

export const setNoCoord = createEvent<boolean>();

export const setAnotherStore = createEvent<boolean>();

export const $user = createStore<Partial<(JWTTokenParsed & KCTokenParsed) | null>>(null);

export const setUser = createEvent<Partial<JWTTokenParsed & KCTokenParsed>>();

$user.on(setUser, (state, payload) => {
	return { ...state, ...payload };
});

$token
	.on(setToken, (_, payload) => {
		return payload;
	})
	.on(delToken, () => {
		return '';
	});

$coordinate.on(setCoordinate, (state, payload) => {
	return payload;
});

$noCoord.on(setNoCoord, (_, payload) => payload);

$anotherStore.on(setAnotherStore, (_, payload) => payload);
