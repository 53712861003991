import { createEffect, createStore, createEvent } from 'effector';

import { IStore, IStoreItem } from 'dto/store.dto';

export const $store = createStore<IStore | null>(null);

export const setStore = createEvent<IStore>();

export const $storeList = createStore<IStore[]>([]);

export const setStoreList = createEvent<IStore[]>();

export const $storeStr = createStore<string>('');

export const setStoreStr = createEvent<string>();

$store.on(setStore, (_, payload) => {
	return payload;
});

$storeList.on(setStoreList, (_, payload) => {
	return payload;
});

$storeStr.on(setStoreStr, (_, payload) => {
	return payload;
});

export const $qrcode = createStore<string>('');

export const setQRCode = createEvent<string>();

$qrcode.on(setQRCode, (_, payload) => {
	return payload;
});
