import { FetchData } from 'use-http';
import { IUser, UserFilter } from 'types/users';
import { deviceDetect } from 'react-device-detect';
import { ICoordinate } from 'models/user';

export const downloadFile = async <TData>(post: FetchData<TData>, files: FileList) => {
	if (files instanceof FileList) {
		const data = new FormData();
		data.append('file', files[0]);
		const result = await post('api/file/download', data);
		return result;
	}
	return files;
};

export const convertUserFilter = (data: UserFilter[]) => {
	if (!Array.isArray(data)) {
		console.error('Expected an array, but got:', data);
		return [];
	}
	console.log('Данные пользователей', data);
	return data.map(item => {
		const value = `${item.secondname ? item.secondname : ''} ${item.firstname ? item.firstname : ''} ${
			item.middlename ? item.middlename : ''
		}`;
		return {
			value: item.userId,
			label: value,
		};
	});
};

export const convertUser = (data: IUser[]) => {
	return data.map((item: IUser) => {
		return {
			value: String(item.id),
			label: `${item.firstname ? item.firstname : ''} ${item.secondname ? item.secondname : ''} ${
				item.middlename ? item.middlename : ''
			}`,
		};
	});
};

export const tokenDecode = (token: string | null) => {
	if (token) {
		const base64UrlasArr = token.split('.');
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join(''),
		);

		// Парсим полученный JSON-объект
		const payload = JSON.parse(jsonPayload);
		return payload;
	}
	return null;
};

export const dataURItoBlob = (dataURI: string) => {
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);

	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
};

interface CheckinResponse {
	statusCode: number;
	message?: string;
	id?: string;
	error?: string;
}

export const registrCheckin = async <T extends CheckinResponse>(
	post: FetchData<T>,
	code?: string,
	token?: string,
	coordinate?: ICoordinate,
	fileId?: number,
): Promise<T> => {
	console.log('registrCheckin', coordinate);
	const checkinRes = await post('/api/checkin/registr', {
		code,
		token,
		userAgent: deviceDetect(window.navigator.userAgent),
		lat: coordinate?.latitude,
		lon: coordinate?.longitude,
		fileId,
		datetimeStr: `${new Date()}`,
		timezoneOffset: new Date().getTimezoneOffset(),
	});

	if (checkinRes?.statusCode) {
		throw new Error(checkinRes.message || 'Ошибка при регистрации в ЭЖ');
	}

	return checkinRes;
};
