import { IUser } from './users';
import { ICheckin } from './checkin';

// типы нарушений
export enum BreachEnum {
	DiffGeo = 'DiffGeo', // Геотег
	DiffToken = 'DiffToken', // Нарушение авторизации
	AnotherShift = 'AnotherShift', // Связанные авторизации
	ErrorGeo = 'ErrorGeo', // ошибка получения координат через браузер
	EndSession = 'EndSession', // 'Незакрытая сессия'
	RegManual = 'RegManual', //Ручная регистрация
}

export interface IBreach {
	id: number;
	data: { diffGeo?: string; total?: number; uri: string };
	user: IUser;
	type: BreachEnum;
	userId: string;
	open: boolean;
	checkinId: string;
	checkin?: ICheckin;
	createdAt: Date;
	updatedAt: Date;
}
