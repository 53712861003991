import useFetch from 'use-http';
import Button from 'components/UI/Button';
import Border from 'components/Border';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { useParams, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { useUnit } from 'effector-react';
import { $coordinate, $token } from 'models/user';
import React from 'react';
import { registrCheckin } from 'utils/helpers';

const waitingTime = process.env.REACT_APP_WAITING_TIME ? +process.env.REACT_APP_WAITING_TIME : 60;
const waitingMessage = process.env.REACT_APP_WAITING_MESSAGE
	? process.env.REACT_APP_WAITING_MESSAGE
	: 'Время ожидания истекло. Отсканируйте qr-код и подтвердите координаты повторно.';

export default function Maps() {
	const { code } = useParams();
	const { post, error } = useFetch();
	const token = useUnit($token);
	const navigate = useNavigate();
	const coordinate = useUnit($coordinate);
	const [loading, setLoading] = React.useState(false);
	const [localError, setLocalError] = React.useState<string | null>(null);
	const timerStarted = React.useRef(false);
	const timeoutId = React.useRef<ReturnType<typeof setTimeout>>();
	const lastVisibilityState = React.useRef<string>(document.visibilityState);
	const [mapError, setMapError] = React.useState<string | undefined>(undefined);

	React.useEffect(() => {
		const handleVisibilityChange = () => {
			console.log('timer Visibility changed:', document.visibilityState);
			if (document.visibilityState === 'visible' && lastVisibilityState.current !== 'visible') {
				if (localStorage.getItem('eventOccurred') === 'true') {
					alert(waitingMessage);
					localStorage.removeItem('eventOccurred');
					navigate('/');
				}
			} else if (document.visibilityState !== 'visible') {
				lastVisibilityState.current = document.visibilityState;
			}
		};

		if (!timerStarted.current) {
			timeoutId.current = setTimeout(() => {
				console.log('timer Timeout triggered');
				localStorage.setItem('eventOccurred', 'true');
				alert(waitingMessage);
				navigate('/');
			}, waitingTime * 1000);
			console.log('timer Timeout set:', timeoutId.current);
			timerStarted.current = true;
		}

		document.addEventListener('visibilitychange', handleVisibilityChange);
		console.log('timer Event listener added');

		return () => {
			if (timeoutId.current) {
				clearTimeout(timeoutId.current);
				console.log('timer Timeout cleared:', timeoutId.current);
			}
			document.removeEventListener('visibilitychange', handleVisibilityChange);
			console.log('timer Event listener removed');
		};
	}, [waitingMessage, waitingTime, navigate]);

	const handleRecalc = () => {
		document.location.reload();
	};

	const handleGeoRight = async () => {
		const fetchData = async () => {
			setLoading(true);
			setLocalError(null);

			try {
				await registrCheckin(post, code, token, coordinate);
				navigate('/');
			} catch (err) {
				if (err instanceof Error) {
					setLocalError(err.message);
				} else {
					setLocalError('Неизвестная ошибка');
				}
				console.error(err);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	};

	React.useEffect(() => {
		if (localError || error) {
			alert(localError || error?.message);
			navigate('/');
		}
	}, [localError, error]);

	const loadMap = React.useCallback(() => {
		setLoading(true);
		setMapError('');

		return (
			<YMaps>
				<Map
					state={{ center: [coordinate?.latitude, coordinate?.longitude], zoom: 15 }}
					style={{ width: '90%', height: '80%', margin: 'auto' }}
				>
					<Placemark geometry={[coordinate?.latitude, coordinate?.longitude]} />
				</Map>
			</YMaps>
		);
	}, [coordinate]);

	const mapComponent = React.useMemo(() => {
		try {
			return loadMap();
		} catch (err) {
			if (err instanceof Error) {
				setMapError(err.message);
			} else {
				setMapError('Неизвестная ошибка');
			}
			return null;
		} finally {
			setLoading(false);
		}
	}, [loadMap]);

	return (
		<Border>
			<div style={{ textAlign: 'center' }} className={styles['maps-wrap']}>
				{loading && <div>Загрузка...</div>}
				{mapError && <div>Ошибка: {mapError}</div>}
				{mapComponent}
				<div className="row">
					<div className="column-xs-7">
						<Button variant="green" type="button" onClick={handleRecalc}>
							Обновить координаты
						</Button>
					</div>
					<div className="column-xs-5">
						<Button variant="green" type="button" onClick={handleGeoRight}>
							Я нахожусь тут
						</Button>
					</div>
					<div className="column-xs-12">
						<br />
						<br />
						<br />
						<a onClick={() => navigate(`/photo/${code}`)} style={{ textDecoration: 'underline' }}>
							Это не мои координаты
						</a>
					</div>
				</div>
			</div>
		</Border>
	);
}
