import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import cn from 'classnames';
import logoWhite from 'assets/img/svg/logo-100x22-white.svg';
import logoViolet from 'assets/img/svg/logo-100x22-violet.svg';
import { useFetch } from 'use-http';
import { tokenDecode } from 'utils/helpers';
import { JWTTokenParsed } from 'dto/user.dto';
import styles from './login.module.scss';
import { $user, setToken, setUser } from 'models/user';
import { useUnit } from 'effector-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { KeycloakProfile } from 'keycloak-js';
import { setStoreStr } from 'models/store';
import { GroupsEnum } from 'types/users';

const host = process.env.REACT_APP_BACKEND_URL;

function Button(props: any) {
	if (!props) {
		return <button></button>;
	}
	const { children, className, isWhite, ...otherProps } = props;

	return (
		<button {...otherProps} className={cn(styles.button, { [styles.isWhite]: isWhite }, className)}>
			{children}
		</button>
	);
}

export default function Login() {
	const { keycloak } = useKeycloak();
	const { post } = useFetch(host);
	const user = useUnit($user);
	const navigate = useNavigate();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const ref = queryParams.get('ref') || '/';

	React.useEffect(() => {
		async function fetchData() {
			if (keycloak.tokenParsed) {
				const userProfile: KeycloakProfile = await keycloak.loadUserProfile();

				const body = {
					email: userProfile?.email,
					groups: keycloak.tokenParsed.groups?.map((group: string) => group.trim().toLocaleLowerCase()) || [],
					num: keycloak.tokenParsed.num ? keycloak?.tokenParsed?.num : null,
					firstname: userProfile?.firstName,
					secondname: userProfile?.lastName,
					token: keycloak.token,
				};

				const jwtToken = await post('/api/auth/graph', body);

				if (!jwtToken?.access_token) {
					keycloak?.logout();
					return;
				}

				setToken(jwtToken?.access_token);

				const payload: JWTTokenParsed = tokenDecode(jwtToken?.access_token);

				console.log('Login', { jwtToken, payload, userProfile, tokenParsed: keycloak.tokenParsed });

				if (jwtToken?.statusCode && (jwtToken?.statusCode !== 200 || jwtToken?.statusCode !== 201)) {
					alert(jwtToken.message);
					keycloak?.logout();
					return;
				}

				if (
					payload &&
					Array.isArray(payload.groups) &&
					(payload.groups.indexOf(GroupsEnum.CLIENT) > -1 ||
						payload.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 ||
						payload.groups.indexOf(GroupsEnum.MANUAL_REG) > -1 ||
						payload.groups.indexOf(GroupsEnum.SESSION_STAT) > -1) &&
					keycloak.tokenParsed.worklist
				) {
					setStoreStr(keycloak.tokenParsed.worklist);
				}

				if (payload && keycloak && keycloak.tokenParsed) {
					setUser({
						...keycloak.tokenParsed,
						...payload,
					});
				}

				navigate(ref);
			}
		}

		if (keycloak.authenticated) {
			fetchData();
		}
	}, [keycloak.authenticated]);

	return (
		<>
			{!keycloak.authenticated ? (
				<div
					style={{ height: '100%' }}
					className={cn(styles.container, { [styles.authorized]: keycloak.authenticated })}
				>
					<header className={cn(styles.header, { container: keycloak.authenticated })}>
						{<img src={keycloak.authenticated ? logoViolet : logoWhite} alt="" />}
					</header>
					<div className={cn(styles.content, { container: keycloak.authenticated })}>
						<div className={styles.wrapper}>
							<div className={styles.container}>
								<div className={styles.title}>Войти в электронный журнал прихода и ухода</div>
								<Button isWhite onClick={() => keycloak.login()}>
									Войти
								</Button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
}
